<template>
<b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <!-- Header: Left Content -->
            <div />

            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
                <h4 class="invoice-numbercs mb-2">
                    Quote #{{ quote.tfr.tfr }}
                </h4>
            </div>
        </div>
            <h2 class="font-weight-bold">{{$t('avItSpecs.avItNetworkingSpecs', "en")}}</h2>
            <div class="pb-4">
                <b-table-simple fixed table-class="text-nowrap print-friendly" v-if="avCount > 0">
                    <b-thead>
                        <b-tr>
                            <b-th class="table-background-color" colspan="8">
                                {{$t('avItSpecs.audioVisual', "en")}}
                            </b-th>
                            <b-th class="table-background-color" colspan="4">
                                {{$t('avItSpecs.count', "en")}}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="border-group-details" v-for="avit_spec in av" :key="avit_spec.id">
                            <b-td colspan="8">
                                {{ avit_spec.name }}
                            </b-td>
                            <b-td colspan="4">
                               {{avit_spec.qty}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div class="pb-4">
                <b-table-simple fixed table-class="text-nowrap print-friendly" v-if="itCount > 0">
                    <b-thead>
                        <b-tr>
                            <b-th class="table-background-color" colspan="8">
                                {{$t('avItSpecs.networking', "en")}}
                            </b-th>
                            <b-th class="table-background-color" colspan="4">
                                {{$t('avItSpecs.count', "en")}}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <!-- Partitions -->
                        <b-tr class="border-group-details" v-for="avit_spec in it" :key="avit_spec.id">
                            <b-td colspan="8">
                                {{ avit_spec.name }}
                            </b-td>
                            <b-td colspan="4">
                               {{avit_spec.qty}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
    </b-card-body>
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
</b-card>

</template>

<script>
import {
    BCard,
    BCardBody,
    BCol,
    BButton,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    VBToggle,
} from 'bootstrap-vue'

export default {
    name: 'ReviewConstructionSpecSection',
    components: {
        BCard,
        BCardBody,
        BCol,
        BRow,
        BTableSimple,
        BButton,
        BTr,
        BTh,
        BThead,
        BTfoot,
        BTd,
        BTbody,
    },
    props: ['quote'],
    computed: {
        av() {
            const avList = ['AV-001','AV-002','AV-003','AV-004','AV-005','AV-006','AV-007','AV-008'];
            return this.quote.avit_spec_quote.filter(n => avList.includes(n.uid))
        },
        avCount() {
            const avList = ['AV-001','AV-002','AV-003','AV-004','AV-005','AV-006','AV-007','AV-008'];
            const count = this.quote.avit_spec_quote.filter(n => avList.includes(n.uid)).length; 
            return count
        },
        it() {
            const itList = ['AI-005','AI-006','AI-007','AI-008','AI-002','AI-003','AI-009','AI-010','AI-011','AI-001'];
            return this.quote.avit_spec_quote.filter(n =>  itList.includes(n.uid))
        },
        itCount() {
            const itList = ['AI-005','AI-006','AI-007','AI-008','AI-002','AI-003','AI-009','AI-010','AI-011','AI-001'];
            const count = this.quote.avit_spec_quote.filter(n =>  itList.includes(n.uid)).length;
            return count
        }
    }
}
</script>

<style scoped>
.disclaimer-exclusions {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #EA5455
}

.card-body {
    padding: 28px;
}
.hide-lines-review{
    display: none !important
}
@media print {
    .pagebreak-table {
        page-break-after: always;
        white-space: nowrap; 
        padding-top: 2em !important;
    }
    tr{
        page-break-inside: avoid; 
        page-break-after: auto;
    }
    .hide-lines{
        display: none !important
    }
    .hide-lines-review{
        display: table !important;
        padding-top: 2em !important;
    }
}
</style>
