<template>
  <b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>
      <h2 class="font-weight-bold mb-1">
        {{ versionLang(quote.base_spec_version).title }}
        <br />
        <h5 class="text-muted my-0" style="margin-top: 5px !important">
          Base Contract Details
        </h5>
        <small class="disclaimer-exclusions my-1" v-if="quote.base_spec_version < 3.5">{{
          versionLang(quote.base_spec_version).disclaimer
        }}</small>
      </h2>
      <div class="pb-4">
        <b-table-simple
          v-if="quote.base_spec_version < 3.5"
          fixed
          table-class="text-nowrap print-friendly"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="3">
                {{ $t("constructionSpecs.scopeOfWork", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="4">
                {{ $t("constructionSpecs.areas", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="10">
                {{ $t("constructionSpecs.specifications", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              class="border-group-details"
              v-for="(sp, index) in versionLang(quote.base_spec_version).specs"
              :key="index"
            >
              <b-td
                colspan="3"
                :style="!sp.border ? 'border-bottom: white 1px solid !important;' : ''"
              >
                <div class="row-label">
                  {{ sp.spec_name }}
                </div>
              </b-td>
              <b-td colspan="4">
                {{ sp.spec_area }}
              </b-td>
              <b-td colspan="10" v-html="sp.spec_description" style="white-space: normal">
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <b-table-simple v-else fixed table-class="text-nowrap print-friendly">
          <b-tbody>
            <b-tr
              class=""
              v-for="(sp, index) in versionLang(quote.base_spec_version).specs"
              :key="index"
            >
              <b-td
                colspan="4"
                style="border-top: 1px transparent; border-bottom: 1px solid #1f292f15"
                v-if="
                  !searchIfSpecSwap(sp.construction_work_id) &&
                  displayCstrnDertail(sp.construction_work_id)
                "
              >
                <div class="row-label">
                  {{ sp.spec_name }}
                </div>
              </b-td>
              <b-td
                colspan="8"
                v-html="quote.base_spec_version  >=3.9 && sp.spec_name =='Meeting Room / Private Office Doors'?'Framed opening glass swing door, acoustically sealed': sp.spec_description"
                style="
                  white-space: normal;
                  border-top: 1px transparent;
                  border-bottom: 1px solid #1f292f15;
                "
                v-if="
                  !searchIfSpecSwap(sp.construction_work_id) &&
                  displayCstrnDertail(sp.construction_work_id)
                "
              >
              </b-td>
              <b-td
                colspan="4"
                style="border-top: 1px transparent; border-bottom: 1px solid #1f292f15"
                v-if="
                  searchIfSpecSwap(sp.construction_work_id) &&
                  displayCstrnDertail(sp.construction_work_id)
                "
              >
                <div class="row-label">
                  {{ sp.spec_name }}
                  <!-- {{ searchSpecSwap(sp.construction_work_id).name }} -->
                </div>
              </b-td>
              <b-td
                colspan="8"
                v-html="searchSpecSwap(sp.construction_work_id).details"
                style="
                  white-space: normal;
                  border-top: 1px transparent;
                  border-bottom: 1px solid #1f292f15;
                "
                v-if="
                  searchIfSpecSwap(sp.construction_work_id) &&
                  displayCstrnDertail(sp.construction_work_id)
                "
              >
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import enLangData from "@/libs/i18n/locales/en.json";
export default {
  name: "ReviewConstructionSpecSection",
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
  },
  props: ["quote"],
  data() {
    return {
      enLang: enLangData.constructionSpecs,
      specs_swap_allowed: [
        5,
        6,
        7,
        8,
        10,
        11,
        12,
        13,
        14,
        31,
        32,
        34,
        36,
        66,
        37,
        40,
        41,
        42,
        43,
        59,
        60,
        61,
        49,
      ],
    };
  },
  mounted() {
    let codes = [];
    this.versionLang(this.quote.base_spec_version).specs.map((e) => {
      if (e.is_editable) {
        codes.push(e.construction_work_id);
      }
    });
  },
  methods: {
    versionLang(version) {
      if (parseFloat(version) < 2.8) {
        return this.enLang.spec_version_1;
      } else if (parseFloat(version) >= 3.5) {
        return this.enLang.spec_version_3;
      } else if (this.quote.geography != "Toronto") {
        return this.enLang.spec_version_1;
      } else {
        return this.enLang.spec_version_2;
      }
    },
    searchIfSpecSwap(construction_work_id) {
      let result = false;
      this.quote.options.map((o) => {
        if (
          o.is_spec_swap &&
          o.budget_group == "Construction" &&
          o.construction_work_id == construction_work_id
        ) {
          if (
            this.specs_swap_allowed.filter((s) => s == o.construction_work_id).length > 0
          ) {
            result = true;
          }
        }
      });
      return result;
    },
    displayCstrnDertail(construction_work_id) {
      let result = true;
      // * search elements using work_id
      let base_cstrn_spec = this.quote.construction_spec_quote.filter(
        (c) => c.construction_work_id == construction_work_id
      );
      if (base_cstrn_spec.length > 0) {
        if (base_cstrn_spec[0].qty * 1 <= 0) {
          result = false;
        }
      }
      this.quote.options.map((o) => {
        if (
          o.budget_group == "Construction" &&
          o.construction_work_id == construction_work_id
        ) {
          // * add validation to define if the item showed has spec swap

          let base_swap_spec = this.quote.options.filter(
            (c) => c.construction_work_id == construction_work_id
          );
          // * validate if the item found has more than 0 qty
          if (base_cstrn_spec.length > 0 && base_swap_spec.length > 0) {
            if (base_cstrn_spec[0].qty * 1 + base_swap_spec[0].quantity * 1 <= 0) {
              result = false;
            } else {
              result = true;
            }
          }
        }
      });
      return result;
    },
    searchSpecSwap(construction_work_id) {
      let result = false;
      result = this.quote.options.find(
        (o) =>
          o.is_spec_swap &&
          o.budget_group == "Construction" &&
          o.construction_work_id == construction_work_id
      );
      return result;
    },
  },
};
</script>

<style scoped>
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
  margin-top: 0px !important;
}

.card-body {
  padding: 28px;
}
.hide-lines-review {
  display: none !important;
}

@media print {
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  .pagebreak-table {
    page-break-after: always;
    white-space: nowrap;
    padding-top: 2em !important;
  }
  .hide-lines {
    display: none !important;
  }
  .hide-lines-review {
    display: table !important;
    padding-top: 2em !important;
  }
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color:white !important;
}
</style>
