<template>
  <b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Quote #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>
      <h2 class="font-weight-bold">
        {{versionLang(quote.base_spec_version).title}}
        <br />
        <small class="disclaimer-exclusions">{{
          versionLang(quote.base_spec_version).disclaimer
        }}</small>
      </h2>
      <div class="pb-4">
        <b-table-simple fixed table-class="text-nowrap print-friendly">
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="3">
                {{ $t("constructionSpecs.scopeOfWork", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="4">
                {{ $t("constructionSpecs.areas", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="10">
                {{ $t("constructionSpecs.specifications", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              class="border-group-details"
              v-for="(sp, index) in versionLang(quote.base_spec_version).specs"
              :key="index"
            >
              <b-td
                colspan="3"
                :style="!sp.border ? 'border-bottom: white 1px solid !important;' : ''"
              >
                <div class="row-label">
                  {{ sp.spec_name }}
                </div>
              </b-td>
              <b-td colspan="4">
                {{ sp.spec_area }}
              </b-td>
              <b-td colspan="10" v-html="sp.spec_description" style="white-space: normal">
              </b-td>
            </b-tr>
            <!-- Partitions -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3">
                                <div class="row-label">
                                    {{$t('constructionSpecs.partitions', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.all', "en")}}
                            </b-td>
                            <b-td colspan="10">
                                <template v-if="quote.geography == 'Toronto'" >
                                    <div v-html="$t('constructionSpecs.drywallSpecTo', 'en')></div>
                                </template>
                                <template v-else-if="quote.geography == 'Montréal'" >
                                    <div v-html="$t('constructionSpecs.drywallSpecMo', 'en')></div>
                                </template>
                            </b-td>
                        </b-tr> -->
            <!-- Glazing -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3">
                                <div class="row-label">
                                   {{$t('constructionSpecs.glazing', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.all', "en")}}
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.glazingDescription', 'en')>
                            </b-td>
                        </b-tr> -->
            <!-- Doors -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                                <div class="row-label">
                                    {{$t('constructionSpecs.doors', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.generalWorkspace', "en")}} <br>
                                </div>
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.doorsGeneralWorkspaceDescription', 'en')>
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.doorsEnclosedRoomsDescription', "en")}}
                            </b-td>
                        </b-tr> -->
            <!-- Flooring -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                                <div class="row-label">
                                    {{$t('constructionSpecs.flooring', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.flooringGeneral', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.flooringGeneralDescription', 'en')>
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.flooringEnclosedRoomsDescription', "en")}}
                            </b-td>
                        </b-tr> -->
            <!-- Ceiling -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                                <div class="row-label">
                                    {{$t('constructionSpecs.ceiling', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.generalWorkspace', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.ceilingGeneralWorkspaceDescription', "en")}}
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.ceilingEnclosedRoomDescription', "en")}}
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms12People', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.ceilingEnclosedRooms12PeopleDescription', "en")}}
                            </b-td>
                        </b-tr> -->
            <!-- Lighting -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                                <div class="row-label">
                                    {{$t('constructionSpecs.lighting', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.generalWorkspace', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.lightingGeneralWorkspaceDescription', 'en') style="white-space: normal;">
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.lightingEnclosedRoomsDescription', 'en')>
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                   {{$t('constructionSpecs.enclosedRooms12People', "en")}} 
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.lightingEnclosedRooms12PeopleDescription', "en")}}
                            </b-td>
                        </b-tr> -->
            <!-- Lighting Controls -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                                <div class="row-label">
                                    {{$t('constructionSpecs.lightingControls', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.generalWorkspace', "en")}} <br>
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.lightingControlsGeneralWorkspaceDescription', "en")}}
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.lightingControlsEnclosedRooms', "en")}}
                            </b-td>
                        </b-tr> -->
            <!-- Electrical -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                                <div class="row-label">
                                    {{$t('constructionSpecs.electrical', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.generalWorkspace', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.electricalGeneralWorkspaceDescription', 'en')>
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.electricalEnclosedRoomsDescription', 'en')>
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.kitchen', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.electricalKitchenDescription', 'en')>
                            </b-td>
                        </b-tr> -->
            <!-- HVAC -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3" style="border-bottom: white 1px solid !important;">
                                <div class="row-label">
                                    {{$t('constructionSpecs.hvac', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.generalWorkspace', "en")}} <br>
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.hvacGeneralWorkspaceDescription', "en")}}
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                            </b-td>
                            <b-td colspan="4">
                                <div>
                                    {{$t('constructionSpecs.enclosedRooms', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.hvacEnclosedRoomsDescription', "en")}}
                            </b-td>
                        </b-tr> -->
            <!-- HVAC Controls -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3">
                                <div class="row-label">
                                    {{$t('constructionSpecs.hvacControls', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.all', "en")}}
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.hvacControlsAllDescription', 'en')>
                            </b-td>
                        </b-tr> -->
            <!-- Life Safety -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3">
                                <div class="row-label">
                                    {{$t('constructionSpecs.lifeSafety', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.all', "en")}}
                            </b-td>
                            <b-td colspan="10">
                                {{$t('constructionSpecs.lifeSafetyAllDescription', "en")}}
                            </b-td>
                        </b-tr> -->
            <!-- Millwork -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3">
                                <div class="row-label">
                                    {{$t('constructionSpecs.millwork', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.kitchen', "en")}}
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.millworkKitchenDescription', 'en')>

                            </b-td>
                        </b-tr> -->
            <!-- Tiling / Countertops -->
            <!-- <b-tr class="border-group-details">
                            <b-td colspan="3">
                                <div class="row-label">
                                    {{$t('constructionSpecs.tiling', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.kitchen', "en")}}
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.tilingKitchenDescription', 'en')>
                                
                            </b-td>
                        </b-tr>                       -->
            <!-- Plumbing -->
            <!-- <b-tr class="border-group-details " >
                            <b-td colspan="3">
                                <div class="row-label">
                                    {{$t('constructionSpecs.plumbing', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.kitchen', "en")}}
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.plumbingKitchenDescription')">
                            </b-td>
                        </b-tr> -->
            <!-- Appliance Set -->
            <!-- <b-tr class="border-group-details ">
                            <b-td colspan="3">
                                <div class="row-label">
                                    {{$t('constructionSpecs.applianceSet', "en")}}
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.kitchen', "en")}}
                            </b-td>
                            <b-td colspan="10" v-html="$t('constructionSpecs.applianceSetKitchenDescription', "en") " style="white-space: normal;">
                            </b-td>
                        </b-tr> -->
          </b-tbody>
        </b-table-simple>
        <!--            
                <b-table-simple fixed table-class="text-nowrap pagebreak hide-lines-review" >
                    <b-thead>
                        <b-tr>
                            <b-th class="table-background-color" colspan="3">
                                Scope of work
                            </b-th>
                            <b-th class="table-background-color" colspan="4">
                                areas
                            </b-th>
                            <b-th class="table-background-color" colspan="10">
                                specifications
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>           
                        <b-tr class="border-group-details" >
                            <b-td colspan="3">
                                <div class="row-label">
                                    Plumbing
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.kitchen', "en")}}
                            </b-td>
                            <b-td colspan="10">
                                Re-use existing stack<br>
                                All drains and supply for appliance sets<br>
                                Filtered water spout<br>
                                Hung hot water tank with leak detection<br>
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="3">
                                <div class="row-label">
                                    Appliance Set
                                </div>
                            </b-td>
                            <b-td colspan="4">
                                {{$t('constructionSpecs.kitchen', "en")}}
                            </b-td>
                            <b-td colspan="10">
                                Dishwasher: 24” stainless steel dishwasher (Frigidaire or equivalent brand)<br>
                                Fridge: 36” stainless steel fridge (Haier or equivalent brand)<br>
                                Microwave: 1000W Microwave with trim kit (Panasonic or equivalent brand)<br>
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple> 
                -->
      </div>
    </b-card-body>
  
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" /></b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import enLangData from "@/libs/i18n/locales/en.json";
export default {
  name: "ReviewConstructionSpecSection",
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
  },
  props: ["quote"],
  data() {
    return {
      enLang: enLangData.constructionSpecs,
    };
  },
  methods: {
    versionLang(version) {
      if (parseFloat(version) < 2.8) {
        return this.enLang.spec_version_1;
      } else if (this.quote.geography != "Toronto") {
        return this.enLang.spec_version_1;
      }else{
        return this.enLang.spec_version_2;
      }
    },
  },
};
</script>

<style scoped>
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
}

.card-body {
  padding: 28px;
}
.hide-lines-review {
  display: none !important;
}

@media print {
  table {
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  thead {
    display: table-header-group;
  }
  tfoot {
    display: table-footer-group;
  }

  .pagebreak-table {
    page-break-after: always;
    white-space: nowrap;
    padding-top: 2em !important;
  }
  .hide-lines {
    display: none !important;
  }
  .hide-lines-review {
    display: table !important;
    padding-top: 2em !important;
  }
}
</style>
