<template>
  <b-card
    v-if="showSiteConditions || showOptions"
    class="invoice-preview-card pagebreak"
    no-body
  >
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Quote #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>

      <!-- Invoice Description: Table -->
      <div v-if="showSiteConditions">
        <h4 style="padding-right: 28px; padding-left: 28px">
          {{ $t("quote.siteConditions", "en") }}
        </h4>
        <br />
        <b-table-simple
          fixed
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9">
                {{ $t("quote.item", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.pricePerSqft", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.subtotal", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(siteCondition, index) in siteConditions"
              :key="index"
              class="border-group-details"
            >
              <template v-if="!siteCondition.is_base_spec">
                <b-td class="row-label" colspan="9">
                  <div class="row-label">
                    {{ siteCondition.name }}
                  </div>
                  <div
                    v-if="siteCondition.details"
                    class="op-sc-details"
                    style="white-space: pre-line"
                  >
                    {{ siteCondition.details }}
                  </div>
                </b-td>
                <b-td
                  :class="
                    [siteCondition.price_per_sqft < 0 ? 'credit ' : ''] +
                    'row-label'
                  "
                  colspan="2"
                  >{{
                        [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs((siteCondition.price_per_sqft)))
                          .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                  }}
                </b-td>
                <b-td
                  :class="[siteCondition.price_per_sqft < 0 ? 'credit ' : '']"
                  colspan="2"
                  >{{
                        [(siteCondition.price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs(siteCondition.total_price))
                          .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                  }}
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr class="border-footer">
              <b-td class="row-label" colspan="9" style="">
                {{ $t("quote.siteConditionsTotals", "en") }}
              </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                      [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs(quote.site_conditions_price_per_sqft))
                        .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td colspan="2"
                >{{
                      [(quote.site_conditions_price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs(quote.site_conditions_total_price))
                        .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <div v-if="showOptions">
        <h4 style="padding-right: 28px; padding-left: 28px; padding-top: 50px">
          {{ $t("quote.additionalScopeAndUpgrades", "en") }}
        </h4>
        <br />
        <b-table-simple
          fixed
          table-class="text-nowrap"
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9">
                {{ $t("quote.item", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.pricePerSqft", "en") }}
              </b-th>
              <b-th class="table-background-color" colspan="2">
                {{ $t("quote.subtotal", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(option, index) in options"
              :key="index"
              class="border-group-details"
            >
              <template v-if="!option.is_base_spec">
                <b-td colspan="9">
                  <div class="row-label">
                    {{ option.name }}
                  </div>
                  <div
                    v-if="option.details"
                    class="op-sc-details"
                    style="white-space: pre-line"
                  >
                    {{ option.details }}
                  </div>
                </b-td>
                <b-td
                  :class="
                    [option.price_per_sqft < 0 ? 'credit ' : ''] + 'row-label'
                  "
                  colspan="2"
                  >{{
                        [(option.price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs((option.price_per_sqft)))
                          .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                  }}
                </b-td>
                <b-td
                  :class="[option.price_per_sqft < 0 ? 'credit ' : '']"
                  colspan="2"
                  >{{
                        [(option.price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs(option.total_price))
                          .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                  }}
                </b-td>
              </template>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr class="border-footer">
              <b-td class="row-label" colspan="9" style="">
                {{ $t("quote.additionalScopeAndUpgradesTotals", "en") }}
              </b-td>
              <b-td class="row-label" colspan="2"
                >{{
                      [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs(quote.options_price_per_sqft))
                        .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td colspan="2"
                >{{
                      [(quote.options_price_per_sqft &lt; 0) ? '-' : ''] + ('$') + Number(Math.abs(quote.options_total_price))
                        .toLocaleString('en-CA', { minimumFractionDigits: 2 })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </div>
      <!-- Spacer -->
      <hr class="invoice-spacing" style="border: 2px solid #82868b" />
      <b-table-simple
        fixed
        style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        table-class="text-nowrap"
      >
        <b-tfoot>
          <b-tr>
            <b-td
              class="row-label turnkey-subtotal-primary"
              colspan="5"
              style="border-top: none"
            />
            <b-td
              class="row-label project-total-label"
              colspan="4"
              style="text-align: right; border-top: none"
            >
              {{ $t("quote.projectTotal", "en") }}
            </b-td>
            <b-td
              class="row-label project-total-number"
              colspan="2"
              style="border-top: none"
              >{{
                "$" +
                Number(
                  quote.construction_cost_per_sqft +
                    quote.site_conditions_construction_price_per_sqft +
                    quote.options_construction_price_per_sqft +
                    quote.construction_buffer * 1 +
                    Number(
                      (!quote.ffe_toggle ? 0 : quote.ffe_price_per_sqft) +
                        quote.options_ffe_price_per_sqft +
                        Number(
                          (!quote.avit_toggle ? 0 : quote.avit_price_per_sqft) +
                            quote.options_avit_price_per_sqft
                        ) +
                        Number(
                          quote.construction_pm_buffer * 1 +
                            quote.design_ame_buffer * 1 +
                            Number(quote.engineering_fee_price) * 1
                        ) +
                        Number(quote.permit_fees_cost_per_sqft)
                    ) +
                    Number(quote.site_conditions_price_per_sqft * 1) +
                    Number(quote.options_price_per_sqft * 1)
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                }) +
                ""
              }}
            </b-td>
            <b-td
              class="project-total-number"
              colspan="2"
              style="border-top: none"
            >
              {{
                "$" +
                Number(
                  Number(
                    quote.construction_buffer * 1 * quote.sqft +
                      siteConditionsConstructionSubtotal +
                      optionsConstructionSubtotal +
                      quote.construction_calc
                  ) +
                    Number(
                      (!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                        optionsFfeSubtotal
                    ) +
                    Number(
                      (!quote.avit_toggle ? 0 : quote.avit_calc) +
                        optionsAvItSubtotal
                    ) +
                    Number(
                      (quote.construction_pm_buffer * 1 +
                        quote.design_ame_buffer * 1 +
                        Number(quote.engineering_fee_price) * 1) *
                        quote.sqft
                    ) +
                    Number(quote.permit_fees) +
                    Number(quote.options_total_price) +
                    Number(quote.site_conditions_total_price)
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                }) +
                ""
              }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card-body>

    <!-- Note -->
    <!-- Note -->
    <!-- <b-card-body class="invoice-padding pt-0">
              <span class="disclaimer">This pricing does not contemplate liquidated damages. If liquidated damages are required, a premium will apply.<br>
                  Quote does NOT include <span v-if="quote.opportunity.geography == 'Toronto'">HST.</span><span v-else>include GST + QST.</span> <br>
                  Quote does NOT include any landlord required reviews and backcharges (incl. after hour fees, commissioning, security, engineer reviews, etc.).<br>
                  Changes to the scope of work listed above will be priced separately and agreed to in writing before additional work is performed. </span>
          </b-card-body> -->
    <b-card-body class="invoice-padding pt-0 footer-quote my-3">
      <span class="confidential-line float-right">{{
        $t("quote.confidential", "en")
      }}</span>
    </b-card-body>
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
  </b-card>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCol,
    BButton,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
  },
  props: {
    quote: {
      type: Object,
      default: [],
    },
    siteConditionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    optionsAvItSubtotal: {
      type: Number,
      default: 0,
    },
    optionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: 0,
    },
    showOptions: {
      type: Boolean,
      default: 0,
    },
    siteConditions: {
      type: Array,
      default: 0,
    },
    showSiteConditions: {
      type: Boolean,
      default: 0,
    },
    optionsFfeSubtotal: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
</style>