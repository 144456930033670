<template>
<b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
        <div class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0">
            <!-- Header: Left Content -->
            <div />

            <!-- Header: Right Content -->
            <div class="mt-md-0 mt-2">
                <h4 class="invoice-numbercs mb-2">
                    Quote #{{ quote.tfr.tfr }}
                </h4>
            </div>
        </div>
            <h2 class="font-weight-bold">{{$t('ffeSpecs.ffeSpecs', "en")}}</h2>
                <b-row  style="padding-bottom: 30px;padding-top: 20px;" v-if="quote.density_occupancy != 0">
                  <b-col cols="3" style="padding-left: 60px;">
                    <label style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;">{{$t('ffeSpecs.occupancy', "en")}}</label>
                    <h3>{{quote.density_occupancy +" " + $t('ffeSpecs.persons', "en")}}</h3>
                  </b-col>
                  <b-col cols="3">
                    <label style="font-weight: 600;font-size: 12px;line-height: 23px;letter-spacing: 0.6px;text-transform: uppercase;color: #B9B9C3;">density</label>
                    <h3>{{Math.ceil(quote.sqft/quote.density_occupancy) + " " + $t('ffeSpecs.sqftperperson', "en")}}</h3>
                  </b-col>
                </b-row>
            <div class="pb-4" v-if="ffeWorkstationsCount > 0">
                <b-table-simple fixed table-class="text-nowrap print-friendly">
                    <b-thead>
                        <b-tr>
                            <b-th class="table-background-color" colspan="8">
                                {{$t('ffeSpecs.workstations', "en")}}
                            </b-th>
                            <b-th class="table-background-color" colspan="4">
                                {{$t('ffeSpecs.count', "en")}}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <!-- Partitions -->
                        <b-tr class="border-group-details" v-for="ffe_spec in ffeWorkstations" :key="ffe_spec.id">
                            <b-td colspan="8">
                                {{ffe_spec.name}}
                            </b-td>
                            <b-td colspan="4">
                               {{ffe_spec.qty}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div style="padding-bottom:30px;" v-if="ffeMeetingRoomsCount > 0">
                <b-table-simple fixed table-class="text-nowrap print-friendly" style="page-break-inside:avoid">
                    <b-thead>
                        <b-tr>
                            <b-th class="table-background-color" colspan="8">
                                {{$t('ffeSpecs.meetingRooms', "en")}}
                            </b-th>
                            <b-th class="table-background-color" colspan="4">
                                {{$t('ffeSpecs.count', "en")}}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <!-- Partitions -->
                        <b-tr class="border-group-details" v-for="ffe_spec in ffeMeetingRooms" :key="ffe_spec.id">
                            <b-td colspan="8">
                                {{ffe_spec.name}}
                            </b-td>
                            <b-td colspan="4">
                               {{ffe_spec.qty}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div style="padding-bottom:30px;" v-if="ffePodsCount > 0">
                <b-table-simple fixed table-class="text-nowrap print-friendly" style="page-break-inside:avoid"> 
                    <b-thead>
                        <b-tr>
                            <b-th class="table-background-color" colspan="8">
                                {{$t('ffeSpecs.pods', "en")}}
                            </b-th>
                            <b-th class="table-background-color" colspan="4">
                                {{$t('ffeSpecs.count', "en")}}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="border-group-details" v-for="ffe_spec in ffePods" :key="ffe_spec.id">
                            <b-td colspan="8">
                                {{ffe_spec.name}}
                            </b-td>
                            <b-td colspan="4">
                               {{ffe_spec.qty}}
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
            <div style="padding-bottom:30px;" v-if="ffeMiscellaneousCount > 0">
                <b-table-simple fixed table-class="text-nowrap print-friendly" style="page-break-inside:avoid"> 
                    <b-thead>
                        <b-tr>
                            <b-th class="table-background-color" colspan="8">
                                {{$t('ffeSpecs.miscellaneous', "en")}}
                            </b-th>
                            <b-th class="table-background-color" colspan="4">
                                {{$t('ffeSpecs.count', "en")}}
                            </b-th>
                        </b-tr>
                    </b-thead>
                    <b-tbody>
                        <b-tr class="border-group-details" v-for="ffe_spec in ffeMiscellaneous" :key="ffe_spec.id">
                            <b-td colspan="8">
                                {{ffe_spec.name}}
                            </b-td>
                            <b-td colspan="4">
                               {{ffe_spec.qty}}
                            </b-td>
                        </b-tr>
                        <b-tr class="border-group-details">
                            <b-td colspan="8">
                                {{$t('ffeSpecs.openSpecMiscellaneous', "en")}}
                            </b-td>
                            <b-td colspan="4">
                               N/A
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>
            </div>
    </b-card-body>
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
</b-card>

</template>

<script>
import {
    BCard,
    BCardBody,
    BCol,
    BButton,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
    VBToggle,
} from 'bootstrap-vue'

export default {
    name: 'ReviewConstructionSpecSection',
    components: {
        BCard,
        BCardBody,
        BCol,
        BRow,
        BTableSimple,
        BButton,
        BTr,
        BTh,
        BThead,
        BTfoot,
        BTd,
        BTbody,
    },
    props: ['quote'],
    computed: {
        occupancy() {
            const results = this.quote.ffe_spec_quote.filter(n => n.uid == 'FS-021');
            const qty = results[0].qty
            return qty
        },
        ffeWorkstations() {
            return this.quote.ffe_spec_quote.filter(n => n.subcategory == 'Workstation')
        },
        ffeWorkstationsCount() {
            const count = this.quote.ffe_spec_quote.filter(n => n.subcategory == 'Workstation').length; 
            return count
        },
        ffeMeetingRooms() {
            return this.quote.ffe_spec_quote.filter(n => n.subcategory == 'Meeting Room')
        },
        ffeMeetingRoomsCount() {
            const count = this.quote.ffe_spec_quote.filter(n => n.subcategory == 'Meeting Room').length; 
            return count
        },
        ffePods() {
            return this.quote.ffe_spec_quote.filter(n => n.subcategory == 'Pod')
        },
        ffePodsCount() {
            const count = this.quote.ffe_spec_quote.filter(n => n.subcategory == 'Pod').length; 
            return count
        },
        ffeMiscellaneous() {
            return this.quote.ffe_spec_quote.filter(n => ['FT-023', 'FT-005', 'FT-009', 'FS-010'].includes(n.uid))
        },
        ffeMiscellaneousCount() {
            const count = this.quote.ffe_spec_quote.filter(n => ['FT-023', 'FT-005', 'FT-009', 'FS-010'].includes(n.uid)).length; 
            return count
        }

    }
}
</script>

<style scoped>
.disclaimer-exclusions {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: #EA5455
}

.card-body {
    padding: 28px;
}
.hide-lines-review{
    display: none !important
}
@media print {
    .pagebreak-table {
        page-break-after: always;
        white-space: nowrap; 
        padding-top: 2em !important;
    }
    tr{
        page-break-inside: avoid; 
        page-break-after: auto;
    }
    .hide-lines{
        display: none !important
    }
    .hide-lines-review{
        display: table !important;
        padding-top: 2em !important;
    }
}
</style>
