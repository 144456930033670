<template>
  <b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Estimate #{{quote.tfr.tfr }}</h4>
        </div>
      </div>
      <div>
        <h2
          style="
            padding-bottom: 1rem;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          "
        >
          Notes & Assumptions
        </h2>
        <div>
          <ol class="notes_assumptions_list">
            <li>Estimate does NOT include HST.</li>
            <li>
              Base Contract Additions are the prices for materials and work above that
              included in the Base Contract and Construction Specifications. These prices
              are eligible for a fixed price contract when executed with a CCDC2 or
              CCDC14.
            </li>
            <li>
              A cash allowance is an estimate for materials or work that is required, but
              which cannot be adequately scoped or priced without further details. When
              the materials or work can be accurately priced, per the CCDC, the Contract
              Price shall be adjusted by Change Order to provide for any difference
              between the cash allowance and the actual cost of the work performed.
            </li>
            <li>Building is free of Union labour associations</li>
            <li>Site clean and clear of furniture and fixtures</li>
            <li>
              Base Building equipment in good working condition. Repairs or replacement of
              base building equipment not included.
            </li>
            <li>Demising partitions are built & finished slab-to-slab</li>
            <li>Site is clear of hazardous material</li>
            <li>
              BAS specific systems (e.g., Encelium, Johnson Controls, Carma meters) is an
              upcharge
            </li>
            <li>New electrical panel and/or transformer is an upcharge</li>
            <li>Replacement of blinds is an upcharge</li>
            <li>Suite entry doors are in good working order</li>
            <li>
              Clearspace does not manage abatement of any hazardous materials. Abatement
              of any hazardous material is an upcharge.
            </li>
            <li>
              Landlord required reviews and backcharges are not included (incl. after hour
              fees, commissioning, security, engineer reviews, etc.)
            </li>
            <li>
              This quote is eligible for fixed pricing when signed with CCDC14, CCDC2 or
              equivalent form.
            </li>
            <li>
              Changes to the scope of work listed above will be priced separately and
              agreed to in writing before additional work is performed.
            </li>
            <li>
              Pricing guarantee contingent on Clearspace’s ability to use trades of its
              choice, provided such trades are of equal or better quality to the Base
              Building Trades, if Base Building Trades cannot complete the required
              improvements within a time frame and at a cost equivalent to the prevailing
              market standards.
            </li>
            <li>
              Pricing does not contemplate liquidated damages. If liquidated damages are
              required, a premium will apply.
            </li>
            <li v-if="quote.base_spec_version>3.6">
              Base building mechanical replacement or renovation scope is not included unless explicitly stated in the estimate above, including but limited to: additional heating and cooling, roof top units, Heat Recovery Ventilator, perimeter rads
            </li>
          </ol>
        </div>
      </div>
      <b-card-body class="invoice-padding pt-0 footer-quote my-3 notprintable">
        <span class="confidential-line float-right">{{
          $t("quote.confidential", "en")
        }}</span>
      </b-card-body>
    </b-card-body>    
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
  },
  props: ["quote"],
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      comments: {},
      options: {},
      siteConditions: {},
      show: true,
      newComment: "",
      assumptionsExclusions: {},
    };
  },
  beforeMount() {
    this.assumptionsExclusions = this.quote.assumptions_exclusions;
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.notes_assumptions_list > li {
  //   font-family:  "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #6e6b7b;
  padding: 10px 5px;
}
</style>
<style>
.cell-styling {
  height: 33px;
}
.cell-styling-double {
  height: 50px;
}
.first-column {
  padding-right: 28px;
  padding-left: 28px;
}
.secondary-column {
  padding-right: 28px;
  padding-left: 28px;
}
.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}
.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
}
.other-notes-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height, or 192% */
  padding-top: 29px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.other-notes-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.card-body {
  padding: 28px;
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color:white !important;
}
</style>
