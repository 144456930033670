<template>
  <div>
    <b-card
      class="invoice-preview-card pagebreak"
      no-body
      v-show="
        (quote.ffe_toggle && quote.ffe_spec_quote.length > 0) ||
        isBaseContractAddition ||
        ffeItems.length > 0 ||
        allowItems.length > 0
      "
    >
      <b-card-body class="invoice-padding pb-0">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <!-- Header: Left Content -->
          <div />

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
          </div>
        </div>
        <h2 class="font-weight-bold">Furniture</h2>
        <h5 class="text-muted">Summary</h5>
        <div class="pb-4 mt-2">
          <b-table-simple
            fixed
            class="mt-1 pt-0"
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
          >
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="9"> Services</b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Price / SQFT
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Subtotal
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
              class="table_sub_header"
                v-if="
                  (quote.ffe_toggle && quote.ffe_spec_quote.length > 0) ||
                  isBaseContractAddition
                "
              >
                <b-td colspan="9"><b>Base Contract</b></b-td>
                <b-td colspan="2" class="text-right">
                  <b>{{
                    Number(
                      (quote.ffe_toggle ? ffe_calc / quote.sqft : 0) +
                        ffeBcSubtotal / quote.sqft
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
                <b-td colspan="2" class="text-right"
                  ><b>{{
                    Number(
                      (quote.ffe_toggle ? ffe_calc : 0) + ffeBcSubtotal
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
              </b-tr>
              <b-tr
                class="border-group-details"
                v-if="quote.ffe_toggle && quote.ffe_spec_quote.length > 0"
              >
                <template v-if="ffeHasItems('Systems Furniture')">
                  <b-td colspan="9">
                    <div class="ml-2">Systems Furniture</div>
                  </b-td>
                  <b-td colspan="2" class="text-muted text-right">
                    {{
                      Number(ffeTotals("Systems Furniture") / quote.sqft).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </b-td>

                  <b-td
                    colspan="2"
                    :class="[ffeTotals('Systems Furniture') < 0 ? 'credit ' : '']"
                    class="text-muted text-right"
                  >
                    {{
                      Number(ffeTotals("Systems Furniture")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr
                class="border-group-details"
                v-if="quote.ffe_toggle || isBaseContractAddition"
              >
                <template v-if="ffeHasItems('Ancillary')">
                  <b-td colspan="9">
                    <div class="ml-2">Ancillary</div>
                  </b-td>
                  <b-td colspan="2" class="text-muted text-right">
                    {{
                      Number(ffeTotals("Ancillary") / quote.sqft).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </b-td>

                  <b-td
                    colspan="2"
                    :class="[ffeTotals('Ancillary') < 0 ? 'credit ' : '']"
                    class="text-muted text-right"
                  >
                    {{
                      Number(ffeTotals("Ancillary")).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr v-if="quote.ffe_toggle">
                <template v-if="ffeTotals('Delivery and Installation') > 0">
                  <b-td colspan="9">
                    <div class="ml-2">Delivery & Installation</div>
                  </b-td>
                  <b-td colspan="2" class="text-muted text-right">
                    {{
                      Number(
                        ffeTotals("Delivery and Installation") / quote.sqft
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>

                  <b-td
                    class="text-muted text-right"
                    colspan="2"
                    :class="[ffeTotals('Delivery and Installation') < 0 ? 'credit ' : '']"
                  >
                    {{
                      Number(ffeTotals("Delivery and Installation")).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </b-td>
                </template>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="ffeItems.length > 0">
              <b-tr class="table_sub_header">
                <b-td colspan="9"><b>Base Contract Additions </b></b-td>
                <b-td colspan="2" class="text-right">
                  <b>{{
                    Number(ffeSubtotal / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
                <b-td colspan="2" class="text-right"
                  ><b>{{
                    Number(ffeSubtotal).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
              </b-tr>
              <b-tr
                class="border-group-details"
                v-for="(c, index) in ffeItems"
                :key="'ffeI' + index"
              >
                <b-td colspan="9">
                  <div class="ml-2">
                    {{ c.name }}
                    <div class="text-muted">
                      {{ c.details }}
                    </div>
                  </div>
                </b-td>
                <b-td
                  :class="[c.total_price < 0 ? 'credit ' : ''] + 'row-label'"
                  colspan="2"
                  style="font-weight: normal"
                  class="text-muted text-right"
                >
                  {{
                    Number(c.total_price / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
                <b-td
                  :class="[c.total_price < 0 ? 'credit ' : '']"
                  total
                  cost
                  colspan="2"
                  style="font-weight: normal"
                  class="text-muted text-right"
                >
                  {{
                    Number(c.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tbody>
            <b-tbody v-if="allowItems.length > 0">
              <b-tr class="table_sub_header">
                <b-td colspan="9"><b>Cash Allowances</b></b-td>
                <b-td colspan="2" class="text-right">
                  <b>{{
                    Number(allowSubtotal / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
                <b-td colspan="2" class="text-right"
                  ><b>{{
                    Number(allowSubtotal).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
                >
              </b-tr>
              <b-tr v-for="(a, index) in allowItems" :key="'allowI' + index">
                <template>
                  <b-td colspan="9">
                    <div class="ml-2">
                      {{ a.name }}
                      <p class="text-muted">
                        {{ a.details }}
                      </p>
                    </div>
                  </b-td>
                  <b-td colspan="2" class="text-muted text-right">
                    {{
                      Number(a.total_price / quote.sqft).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>

                  <b-td colspan="2" class="text-muted text-right">
                    {{
                      Number(a.total_price).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <hr
            class="invoice-spacing mt-2"
            style="border: 1px solid rgba(50, 61, 76, 1)"
          />
          <b-table-simple
            fixed
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
            table-class="text-nowrap"
          >
            <b-tfoot>
              <b-tr>
                <b-td
                  class="row-label project-total-label"
                  colspan="9"
                  style="border-top: none"
                >
                  <h4 style="font-weight: 600">Furniture Subtotal</h4>
                </b-td>
                <b-td
                  class="row-label project-total-number text-right"
                  colspan="2"
                  style="border-top: none"
                >
                  {{
                    Number(
                      (quote.ffe_toggle ? ffe_calc / quote.sqft : 0) +
                        (ffeBcSubtotal + ffeSubtotal + Number(allowSubtotal)) / quote.sqft
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
                <b-td
                  class="project-total-number text-right"
                  colspan="2"
                  style="border-top: none"
                >
                  {{
                    Number(
                      (quote.ffe_toggle ? ffe_calc : 0) +
                        ffeSubtotal +
                        Number(allowSubtotal) +
                        ffeBcSubtotal
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>
      </b-card-body>
      <b-card-body class="invoice-padding notprintable pt-0 footer-quote my-3">
        <span class="confidential-line float-right">{{
          $t("quote.confidential", "en")
        }}</span>
      </b-card-body>
      <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
    </b-card>
    <b-card
      class="invoice-preview-card pagebreak"
      no-body
      v-show="quote.ffe_toggle && quote.ffe_spec_quote.length > 0"
    >
      <b-card-body class="invoice-padding pb-0">
        <div
          class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
        >
          <!-- Header: Left Content -->
          <div />

          <!-- Header: Right Content -->
          <div class="mt-md-0 mt-2">
            <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
          </div>
        </div>
        <h2 class="font-weight-bold">Furniture</h2>
        <h5 class="text-muted">Base Contract Details</h5>
        <div class="pb-4 mt-2" v-if="ffeHasItems('Systems Furniture')">
          <b-table-simple
            fixed
            class="mt-0 pt-0"
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
          >
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="4">
                  Systems furniture
                </b-th>
                <b-th class="table-background-color" colspan="4"> Manufacturer </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Quantity
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Price/Unit
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  subTotal
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(ffe, index) in systemFurniture"
                :key="'SfI' + index"
                class="border-group-details"
              >
                <template v-if="ffe.qty > 0">
                  <b-td colspan="4">
                    {{ ffe.name }}
                    <div v-if="Number(quote.base_spec_version) >= 3.4">
                      <div>
                        <small v-if="!ffe.specification_swap" class="text-muted">{{
                          ffe.element.specification_key
                        }}</small>
                        <small v-else class="text-muted">{{
                          ffe.specification_swap.name
                        }}</small>
                      </div>
                    </div>
                  </b-td>
                  <b-td colspan="4">
                    <div v-if="!ffe.specification_swap">{{ ffe.element.vendor }}</div>
                    <div v-else>{{ ffe.specification_swap.manufacturer }}</div>
                  </b-td>

                  <b-td colspan="2" class="text-right">
                    {{ ffe.qty }}
                  </b-td>

                  <b-td colspan="2" class="text-right">
                    {{
                      Number(
                        !ffe.specification_swap
                          ? ffe.supply_price
                          : ffe.specification_swap.unit_cogs
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                  <b-td class="text-right" colspan="2">
                    {{
                      Number(
                        !ffe.specification_swap
                          ? ffe.supply_price * ffe.qty
                          : ffe.specification_swap.total_price
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </div>
        <div class="pb-4 mt-2" v-if="ffeHasItems('Ancillary')">
          <b-table-simple
            fixed
            class="mt-0 pt-0"
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
          >
            <b-thead>
              <b-tr>
                <b-th class="table-background-color" colspan="4"> Ancillary </b-th>
                <b-th class="table-background-color" colspan="4"> Manufacturer </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Quantity
                </b-th>
                <b-th class="table-background-color text-right" colspan="2">
                  Price/Unit</b-th
                >
                <b-th class="table-background-color text-right" colspan="2">
                  subTotal
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr
                v-for="(ffe, index) in ancillaryFurniture"
                :key="index"
                class="border-group-details"
              >
                <template v-if="ffe.qty > 0">
                  <b-td colspan="4">
                    <div>
                      {{ ffe.name }}
                      <div v-if="Number(quote.base_spec_version) >= 3.4">
                        <small v-if="!ffe.specification_swap" class="text-muted">{{
                          ffe.element.specification_key
                        }}</small>
                        <small v-else class="text-muted">{{
                          ffe.specification_swap.name
                        }}</small>
                      </div>
                    </div>
                  </b-td>
                  <b-td colspan="4">
                    <div v-if="!ffe.specification_swap">{{ ffe.element.vendor }}</div>
                    <div v-else>{{ ffe.specification_swap.manufacturer }}</div>
                  </b-td>

                  <b-td colspan="2" class="text-right">
                    {{ ffe.qty }}
                  </b-td>

                  <b-td colspan="2" class="text-right">
                    {{
                      Number(
                        !ffe.specification_swap
                          ? ffe.supply_price
                          : ffe.specification_swap.unit_cogs
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                  <b-td class="text-right" colspan="2">
                    {{
                      Number(
                        !ffe.specification_swap
                          ? ffe.supply_price * ffe.qty
                          : ffe.specification_swap.total_price
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                </template>
              </b-tr>
              <b-tr>
                <template>
                  <b-td colspan="4">
                    <div>Open Spec Ancillary Cash Allowance</div>
                  </b-td>
                  <b-td class="table-background-color" colspan="4"> </b-td>
                  <b-td class="table-background-color" colspan="2"> </b-td>
                  <b-td colspan="2"> </b-td>
                  <b-td colspan="2" class="text-right">
                    {{
                      Number(openSpecSubtotal).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}</b-td
                  >
                </template>
              </b-tr>
            </b-tbody>
          </b-table-simple>
          <b-table-simple
            fixed
            class="mt-2 pt-0"
            style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
          >
            <b-tfoot>
              <b-tr class="border-footer" style="border-color: rgba(50, 61, 76, 1)">
                <b-td colspan="4" style=" font-weight: 600;">Delivery & Installation</b-td>
                <b-td class="table-background-color" colspan="4"> </b-td>
                <b-td class="table-background-color" colspan="2"> </b-td>
                <b-td colspan="2"> </b-td>
                <b-td colspan="2" class="text-right; font-weight:400;"
                  >{{
                    Number(ffeDITotals()).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </b-tfoot>
          </b-table-simple>
        </div>
      </b-card-body>
      <b-card-body class="invoice-padding notprintable pt-0 footer-quote my-3">
        <span class="confidential-line float-right">{{
          $t("quote.confidential", "en")
        }}</span>
      </b-card-body>
      <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";

export default {
  name: "ReviewConstructionSpecSection",
  components: {
    BCard,
    BCardBody,
    BCol,
    BRow,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
  },
  props: ["quote"],
  data() {
    return {
      ffeCategories: ["Systems Furniture", "Ancillary"],
    };
  },
  computed: {
    systemFurniture() {
      let results = this.quote.ffe_spec_quote.filter((x) => x.subcategory != "Ancillary");
      return results;
    },
    ancillaryFurniture() {
      let results = this.quote.ffe_spec_quote.filter(
        (x) => x.subcategory == "Ancillary" && x.element.vendor != "[OPEN SPEC]"
      );
      return results;
    },
    openSpecSubtotal() {
      let results = this.quote.ffe_spec_quote
        .filter((x) => x.subcategory == "Ancillary" && x.element.vendor == "[OPEN SPEC]")
        .reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.supply_price * obj.qty)
              : obj.specification_swap.total_price * 1) + total,
          0
        );
      return results;
    },
    ffeSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeBcSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "FF&E" && c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ffeItems() {
      let result = this.quote.options.filter(
        (c) => c.budget_group == "FF&E" && !c.is_base_spec
      );
      return result;
    },
    allowItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "FF&E");
      return result;
    },
    allowSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "FF&E")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    isBaseContractAddition() {
      let exist = false;
      let result = this.quote.options.filter(
        (c) => c.budget_group == "FF&E" && c.is_base_spec
      );
      if (result.length > 0) {
        exist = true;
      }
      return exist;
    }, // add ffe calc
    ffe_calc() {
      let sum = 0;
      sum = this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_price)
            : obj.specification_swap.total_price * 1 +
              obj.specification_swap.total_delivery_install * 1) + total,
        0
      );
      return sum;
    },
  },
  methods: {
    ffeDITotals() {
      let total = 0;

      total = +this.quote.ffe_spec_quote.reduce(
        (total, obj) =>
          (obj.specification_swap == null
            ? Number(obj.total_delivery_install * 1)
            : obj.specification_swap.total_delivery_install * 1) + total,
        0
      );
      return total;
    },
    ffeHasItems(group) {
      let has = false;
      let result = [];
      if (group == "Ancillary") {
        result = this.quote.ffe_spec_quote.filter((x) => x.subcategory == "Ancillary");
      } else {
        result = this.quote.ffe_spec_quote.filter((x) => x.subcategory != "Ancillary");
      }
      if (result.length > 0) {
        has = true;
      }
      return has;
    },
    ffeTotals(option) {
      let total = 0;
      if (option == "Systems Furniture") {
        total = +this.quote.ffe_spec_quote
          .filter((x) => x.subcategory != "Ancillary")
          .reduce(
            (total, obj) =>
              (obj.specification_swap == null
                ? Number(obj.supply_price * obj.qty)
                : obj.specification_swap.total_price * 1) + total,
            0
          );
      }
      if (option == "Ancillary") {
        total = 0;
        if (this.quote.ffe_toggle) {
          total = +this.quote.ffe_spec_quote
            .filter((x) => x.subcategory == "Ancillary")
            .reduce(
              (total, obj) =>
                (obj.specification_swap == null
                  ? Number(obj.supply_price * obj.qty)
                  : obj.specification_swap.total_price * 1) + total,
              0
            );
        }
        total += this.ffeBcSubtotal;
      }
      if (option == "Delivery and Installation") {
        total = +this.quote.ffe_spec_quote.reduce(
          (total, obj) =>
            (obj.specification_swap == null
              ? Number(obj.total_delivery_install)
              : obj.specification_swap.total_delivery_install * 1) + total,
          0
        );
      }
      return total;
    },
  },
};
</script>

<style scoped>
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
}

.card-body {
  padding: 28px;
}
.hide-lines-review {
  display: none !important;
}
@media print {
  .pagebreak-table {
    page-break-after: always;
    white-space: nowrap;
    padding-top: 2em !important;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  .hide-lines {
    display: none !important;
  }
  .hide-lines-review {
    display: table !important;
    padding-top: 2em !important;
  }
}
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color:white !important;
}
</style>
