<template>
  <div>
    <b-card class="invoice-preview-card notprintable" no-body style="margin-bottom: 0px">
      <b-card-body>
        <b-row>
          <b-col cols="10">
            <h2
              style="
                font-weight: 500;
                font-size: 24px;
                line-height: 24px;
                display: flex;
                align-items: center;
              "
            >
              Construction Tender
            </h2>
          </b-col>
          <b-col cols="2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              block
              variant="outline-secondary"
              @click="tenderExport"
            >
              <uil-file-export class="logo" size="15px" />Export
            </b-button>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <app-collapse class="dpm-toggles notprintable">
      <app-collapse-item :title="'Finance'" :amountClassProp="''">
        <b-table-simple
          caption-top
          responsive
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="2"> CATEGORY </b-th>
              <b-th class="table-background-color" colspan="3"> TOTAL PRICE(w/ D&I) </b-th>
              <b-th class="table-background-color" colspan="2"> TOTAL COSTS </b-th>
              <b-th class="table-background-color" colspan="2"> TOTAL PROFIT </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr class="">
              <b-td class="row-label" colspan="2"> CSTRN </b-td>
              <b-td class="" colspan="3" style="vertical-align: top"
                >{{
                  "$" +
                  Number(
                    quote.construction_buffer * 1 * quote.sqft +
                      quote.construction_calc +
                      siteConditionsConstructionPriceTotal +
                      optionsConstructionPriceTotal
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td colspan="2" style="vertical-align: top"
                >{{
                  "$" +
                  Number(
                    quote.construction_calc +
                      siteConditionsConstructionCostTotal +
                      optionsConstructionCostTotal
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td colspan="2" style="vertical-align: top">
                {{
                  quote.construction_calc +
                    siteConditionsConstructionPriceTotal +
                    optionsConstructionPriceTotal +
                    quote.construction_buffer * quote.sqft -
                    (quote.construction_calc +
                      siteConditionsConstructionCostTotal +
                      optionsConstructionCostTotal) <
                  0
                    ? " -"
                    : ""
                }}
                {{
                  "$" +
                  Math.abs(
                    quote.construction_calc +
                      siteConditionsConstructionPriceTotal +
                      optionsConstructionPriceTotal +
                      quote.construction_buffer * quote.sqft -
                      (quote.construction_calc +
                        siteConditionsConstructionCostTotal +
                        optionsConstructionCostTotal)
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
            </b-tr>

            <!-- <b-tr
              v-show="
                (!quote.ffe_toggle || quote.options_ffe_price_per_sqft != 0) &&
                optionsFfePriceTotal != 0
              "
              class=""
            >
              <b-td class="row-label" colspan="2">
                FF&E
                <br />
              </b-td>
              <b-td class="" colspan="3" style="vertical-align: top">
                {{ optionsFfePriceTotal < 0 ? " -" : "" }}
                {{
                  "$" +
                  Math.abs(optionsFfePriceTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{ optionsFfeCostTotal < 0 ? " -" : "" }}
                {{
                  "$" +
                  Math.abs(optionsFfeCostTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{ totalProfitValue(optionsFfePriceTotal, optionsFfeCostTotal) }}
              </b-td>
            </b-tr> -->
            <b-tr class="">
              <b-td class="row-label" colspan="2">
                FF&E
                <br />
              </b-td>
              <b-td class="" colspan="3" style="vertical-align: top">
                {{
                  (!quote.ffe_toggle
                    ? optionsFfePriceTotal
                    : quote.ffe_calc + optionsFfePriceTotal) < 0
                    ? " -"
                    : ""
                }}
                {{
                  "$" +
                  Math.abs(
                    !quote.ffe_toggle
                      ? optionsFfePriceTotal
                      : quote.ffe_calc + optionsFfePriceTotal
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{
                  (!quote.ffe_toggle
                    ? optionsFfeCostTotal
                    : ffeTotalCost + quote.ffe_installation + optionsFfeCostTotal) < 0
                    ? " -"
                    : ""
                }}

                {{
                  "$" +
                  Math.abs(
                    !quote.ffe_toggle
                      ? optionsFfeCostTotal
                      : ffeTotalCost + quote.ffe_installation + optionsFfeCostTotal
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{
                  totalProfitValue(
                    !quote.ffe_toggle
                      ? optionsFfePriceTotal
                      : quote.ffe_calc + optionsFfePriceTotal,
                    !quote.ffe_toggle
                      ? optionsFfeCostTotal
                      : ffeTotalCost + quote.ffe_installation + optionsFfeCostTotal
                  )
                }}
              </b-td>
            </b-tr>
            <!-- <b-tr
              v-show="
                (!quote.avit_toggle || quote.options_avit_price_per_sqft != 0) &&
                optionsAvItPriceTotal != 0
              "
              class=""
            >
              <b-td class="row-label" colspan="2"> AV/IT </b-td>
              <b-td class="" colspan="3" style="vertical-align: top">
                {{ optionsAvItPriceTotal < 0 ? " -" : "" }}
                {{
                  "$" +
                  Math.abs(optionsAvItPriceTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{ optionsAvItCostTotal < 0 ? " -" : "" }}
                {{
                  "$" +
                  Math.abs(optionsAvItCostTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{ totalProfitValue(optionsAvItPriceTotal, optionsAvItCostTotal) }}
              </b-td>
            </b-tr> -->
            <b-tr class="">
              <b-td class="row-label" colspan="2"> AV/IT </b-td>
              <b-td class="" colspan="3" style="vertical-align: top">
                {{
                  (!quote.avit_toggle
                    ? optionsAvItPriceTotal
                    : quote.avit_calc + optionsAvItPriceTotal) < 0
                    ? " -"
                    : ""
                }}
                {{
                  "$" +
                  Math.abs(
                    !quote.avit_toggle
                      ? optionsAvItPriceTotal
                      : quote.avit_calc + optionsAvItPriceTotal
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{
                  (!quote.avit_toggle
                    ? optionsAvItCostTotal
                    : avitTotalCost + quote.avit_installation + optionsAvItCostTotal) < 0
                    ? " -"
                    : ""
                }}

                {{
                  "$" +
                  Math.abs(
                    !quote.avit_toggle
                      ? optionsAvItCostTotal
                      : avitTotalCost + quote.avit_installation + optionsAvItCostTotal
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td class="" colspan="2" style="vertical-align: top">
                {{
                  totalProfitValue(
                    !quote.avit_toggle
                      ? optionsAvItPriceTotal
                      : quote.avit_calc + optionsAvItPriceTotal,
                    !quote.avit_toggle
                      ? optionsAvItCostTotal
                      : avitTotalCost + quote.avit_installation + optionsAvItCostTotal
                  )
                }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="row-label" colspan="2"> CHQ </b-td>
              <b-td class="" colspan="3"
                >{{
                  Number(
                    ((quote.construction_pm_buffer * 1 +
                      quote.design_ame_buffer * 1 +
                      Number(quote.engineering_fee_price)) *
                      quote.sqft) +
                      Number(scAllowPriceSubtotal) 
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td class="" colspan="2"
                >{{
                  Number((quote.engineering_fee_cost * 1 * quote.sqft)+ Number(scAllowCostSubtotal)).toLocaleString(
                    "en-CA",
                    {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    }
                  )
                }}
              </b-td>
              <b-td class="" colspan="2">
                {{
                  (
                    (((quote.construction_pm_buffer * 1 +
                      quote.design_ame_buffer * 1 +
                      Number(quote.engineering_fee_price)) *
                      quote.sqft) +
                      Number(scAllowPriceSubtotal)) -
                      ((quote.engineering_fee_cost * 1 * quote.sqft) +
                      Number(scAllowCostSubtotal))
                  ).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
            <b-tr>
              <b-td class="row-label" colspan="2"> OTH </b-td>
              <b-td class="" colspan="3"
                >{{
                  "$" +
                  Number(quote.permit_fees).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </b-td>
              <b-td colspan="2"
                >{{
                  "$" +
                  Number(
                    quote.permit_fees_cost_per_sqft * quote.sqft
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td colspan="2">
                {{
                  quote.permit_fees - quote.permit_fees_cost_per_sqft * quote.sqft < 0
                    ? " -"
                    : ""
                }}
                {{
                  "$" +
                  Math.abs(
                    quote.permit_fees - quote.permit_fees_cost_per_sqft * quote.sqft
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tfoot>
            <b-tr class="border-footer">
              <b-td class="row-label" colspan="2"> Total </b-td>
              <b-td class="" colspan="3"
                >{{
                  "$" +
                  Number(
                    Number(
                      quote.construction_pm_buffer * 1 +
                        quote.design_ame_buffer * 1 +
                        Number(quote.engineering_fee_price) * 1
                    ) *
                      quote.sqft +
                      Number(quote.permit_fees) +
                      Number(
                        (!quote.ffe_toggle ? 0 : quote.ffe_calc) + optionsFfePriceTotal
                      ) +
                      Number(
                        (!quote.avit_toggle ? 0 : quote.avit_calc) + optionsAvItPriceTotal
                      ) +
                      Number(
                        quote.construction_buffer * 1 * quote.sqft +
                          quote.construction_calc +
                          siteConditionsConstructionPriceTotal +
                          optionsConstructionPriceTotal
                      )+ 
                      Number(scAllowPriceSubtotal)
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td colspan="2"
                >{{
                  "$" +
                  Number(
                    quote.construction_calc +
                      siteConditionsConstructionCostTotal +
                      optionsConstructionCostTotal +
                      ((!quote.ffe_toggle ? 0 : ffeTotalCost + quote.ffe_installation) +
                        optionsFfeCostTotal) +
                      ((!quote.avit_toggle
                        ? 0
                        : avitTotalCost + quote.avit_installation) +
                        optionsAvItCostTotal) +
                      quote.engineering_fee_cost * 1 * quote.sqft +
                      quote.permit_fees_cost_per_sqft * quote.sqft +
                      Number(scAllowCostSubtotal)
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
              <b-td colspan="2">
                {{
                  quote.construction_calc +
                    siteConditionsConstructionPriceTotal +
                    optionsConstructionPriceTotal +
                    quote.construction_buffer * quote.sqft -
                    (quote.construction_calc +
                      siteConditionsConstructionCostTotal +
                      optionsConstructionCostTotal) +
                    ((!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                      optionsFfePriceTotal -
                      ((!quote.ffe_toggle ? 0 : ffeTotalCost + quote.ffe_installation) +
                        optionsFfeCostTotal)) +
                    ((!quote.avit_toggle ? 0 : quote.avit_calc) +
                      optionsAvItPriceTotal -
                      ((!quote.avit_toggle
                        ? 0
                        : avitTotalCost + quote.avit_installation) +
                        optionsAvItCostTotal)) +
                    ((quote.construction_pm_buffer * 1 +
                      quote.design_ame_buffer * 1 +
                      Number(quote.engineering_fee_price)) *
                      quote.sqft -
                      quote.engineering_fee_cost * 1 * quote.sqft) +
                    (quote.permit_fees - quote.permit_fees_cost_per_sqft * quote.sqft) <
                  0
                    ? " -"
                    : ""
                }}
                {{
                  "$" +
                  Math.abs(
                    quote.construction_calc +
                      siteConditionsConstructionPriceTotal +
                      optionsConstructionPriceTotal  + 
                      Number(scAllowPriceSubtotal)+
                      quote.construction_buffer * quote.sqft -
                      (quote.construction_calc +
                        siteConditionsConstructionCostTotal +
                        optionsConstructionCostTotal  + 
                      Number(scAllowCostSubtotal)) +
                      ((!quote.ffe_toggle ? 0 : quote.ffe_calc) +
                        optionsFfePriceTotal -
                        ((!quote.ffe_toggle ? 0 : ffeTotalCost + quote.ffe_installation) +
                          optionsFfeCostTotal)) +
                      ((!quote.avit_toggle ? 0 : quote.avit_calc) +
                        optionsAvItPriceTotal -
                        ((!quote.avit_toggle
                          ? 0
                          : avitTotalCost + quote.avit_installation) +
                          optionsAvItCostTotal)) +
                      ((quote.construction_pm_buffer * 1 +
                        quote.design_ame_buffer * 1 +
                        Number(quote.engineering_fee_price)) *
                        quote.sqft -
                        quote.engineering_fee_cost * 1 * quote.sqft) +
                      (quote.permit_fees - quote.permit_fees_cost_per_sqft * quote.sqft)
                  ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                }}
              </b-td>
            </b-tr>
          </b-tfoot>
        </b-table-simple>
      </app-collapse-item>
    </app-collapse>
    <app-collapse class="dpm-toggles notprintable">
      <app-collapse-item :title="'Design Finishes'" :amountClassProp="''">
        <div style="padding-right: 15px; padding-left: 15px">
          <b-row>
            <b-col cols="4">
              <h3>{{ lightFixturesAmount }}</h3>
              <label
                style="
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 23px;
                  letter-spacing: 0.6px;
                  text-transform: uppercase;
                  color: #b9b9c3;
                "
                >Maximum # of Linear Fixtures (excluding Rooms)</label
              >
            </b-col>
            <b-col cols="4">
              <h3>{{ duplexReceptaclesAmount }}</h3>
              <label
                style="
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 23px;
                  letter-spacing: 0.6px;
                  text-transform: uppercase;
                  color: #b9b9c3;
                "
                >Maximum # of 15A duplex Receptacle (excluding rooms)</label
              >
            </b-col>
            <b-col cols="4">
              <h3>{{ convenienceDuplexReceptaclesAmount }}</h3>
              <label
                style="
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 23px;
                  letter-spacing: 0.6px;
                  text-transform: uppercase;
                  color: #b9b9c3;
                "
                >Convenience 15A Duplex receptacles</label
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <hr style="padding-bottom: 40px" />
              <small style="color: #b9b9c3"
                >All prices include supply & install unless otherwise stated</small
              >
              <b-table
                :fields="fieldsFinishes"
                :items="designFinishesTable"
                responsive="true"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle" />
                    <strong> Loading...</strong>
                  </div>
                </template>

                <template #cell(name)="data">
                  <div>
                    {{
                      data.item.name +
                      ([8, 31, 32, 33, 41].includes(data.item.construction_work_id)
                        ? " (supply only)"
                        : "")
                    }}
                    <br />
                    <span
                      v-if="data.item.details"
                      style="
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #b9b9c3;
                        white-space: pre-line;
                      "
                    >
                      {{ data.item.details }}</span
                    >
                  </div>
                </template>
                <template #cell(qty)="data">
                  <div>
                    {{
                      Number(
                        data.item.qty +
                          constructionAdjustmentTotalQuantity(
                            data.item.construction_work_id
                          )
                      ).toLocaleString(undefined, { minimumFractionDigits: 2 }) +
                      " " +
                      data.item.unit_type
                    }}
                  </div>
                </template>
                <template #cell(cost_per_sqft)="data">
                  <div>
                    {{
                      "$" +
                      Number(
                        data.item.unit_cost +
                          constructionAdjustmentTotalUnitCost(
                            data.item.construction_work_id
                          )
                      ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                    }}
                  </div>
                </template>
                <template #head(cost_per_sqft)>
                  <span>COGS / Unit</span>
                </template>
                <template #head(total_cost)>
                  <span>Total COGS</span>
                </template>
                <template #cell(total_cost)="data">
                  <div>
                    {{
                      "$" +
                      Number(
                        data.item.total_cost +
                          constructionAdjustmentTotalCost(data.item.construction_work_id)
                      ).toLocaleString(undefined, { minimumFractionDigits: 2 })
                    }}
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </app-collapse-item>
    </app-collapse>
    <app-collapse class="dpm-toggles notprintable">
      <app-collapse-item
        :title="'Furniture, Fixtures & Equipment'"
        :amountClassProp="quote.ffe_toggle ? '' : 'muted'"
        :collapsableItem="quote.ffe_toggle ? true : false"
      >
        <div style="padding-right: 15px; padding-left: 15px">
          <b-row>
            <b-col cols="3">
              <label
                style="
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 23px;
                  letter-spacing: 0.6px;
                  text-transform: uppercase;
                  color: #b9b9c3;
                "
                >Total FF&E Budget</label
              >
              <h3>
                {{
                  "$" +
                  Number(ffeTableTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </h3>
            </b-col>
            <b-col cols="3">
              <label
                style="
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 23px;
                  letter-spacing: 0.6px;
                  text-transform: uppercase;
                  color: #b9b9c3;
                "
                >Closed Spec Ancillary</label
              >
              <h3>
                {{
                  "$" +
                  Number(closedSpecTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </h3>
            </b-col>
            <b-col cols="3">
              <label
                style="
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 23px;
                  letter-spacing: 0.6px;
                  text-transform: uppercase;
                  color: #b9b9c3;
                "
                >OPEN Spec Ancillary</label
              >
              <h3>
                {{
                  "$" +
                  Number(openSpecTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </h3>
            </b-col>
            <b-col cols="3">
              <label
                style="
                  font-weight: 600;
                  font-size: 12px;
                  line-height: 23px;
                  letter-spacing: 0.6px;
                  text-transform: uppercase;
                  color: #b9b9c3;
                "
                >Clearspace</label
              >
              <h3>
                {{
                  "$" +
                  Number(orizealTotal).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                  })
                }}
              </h3>
            </b-col>
          </b-row>
          <hr style="padding-bottom: 40px" />
          <b-row>
            <b-col cols="9">
              <b-form-group>
                <b-form-radio-group
                  id="btn-radios-1"
                  v-model="selectedRadio"
                  button-variant="outline-primary"
                  :options="optionsRadio"
                  buttons
                  name="radios-btn-default"
                />
              </b-form-group>
            </b-col>
            <b-col cols="3">
              <b-input-group class="input-group-merge">
                <b-input-group-prepend is-text>
                  <feather-icon icon="SearchIcon" />
                </b-input-group-prepend>
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  class="d-inline-block"
                  placeholder="Search"
                ></b-form-input>
              </b-input-group>
            </b-col>
            <b-col cols="12">
              <b-table
                :fields="fieldsFfe"
                :items="ffeItems"
                responsive="true"
                :filter="filter"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle" />
                    <strong> Loading...</strong>
                  </div>
                </template>

                <template #cell(name)="data">
                  <div>
                    {{ data.item.name }} <br />
                    <span
                      v-if="data.item.details"
                      style="
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 18px;
                        letter-spacing: 0px;
                        text-align: left;
                        color: #b9b9c3;
                        white-space: pre-line;
                      "
                    >
                      {{ data.item.details }}</span
                    >
                  </div>
                </template>
                <template #cell(qty)="data">
                  <div>
                    {{ data.item.qty }}
                  </div>
                </template>
                <template #cell(cost_per_sqft)="data">
                  <div>
                    {{
                      "$" +
                      Number(data.item.cost_per_sqft).toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                      })
                    }}
                  </div>
                </template>
                <template #cell(unit_price)="data">
                  {{
                              [(data.item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.supply_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}
                </template>
                <template #cell(total_price)="data">
                  {{
                              [(data.item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.supply_price * data.item.qty))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}
                </template>
              </b-table>
            </b-col>
          </b-row>
        </div>
      </app-collapse-item>
    </app-collapse>
    <app-collapse class="dpm-toggles notprintable">
      <app-collapse-item
        :title="'Site Conditions, Additional Scope & Upgrades'"
        :amountClassProp="siteConditions.length > 0 || options.length > 0 ? '' : 'muted'"
        :collapsableItem="siteConditions.length > 0 || options.length > 0 ? true : false"
      >
        <b-row style="padding-right: 15px; padding-left: 15px">
          <b-col>
            <h3
              style="
                height: 35px !important;
                font-weight: 500 !important;
                font-size: 21px !important;
                line-height: 21px !important;
              "
            >
              Site Conditions
            </h3>
            <b-table :fields="fields" :items="siteConditions" responsive="true">
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(name)="data">
                <div>
                  {{ data.item.name }} <br />
                  <span
                    v-if="data.item.details"
                    style="
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px;
                      letter-spacing: 0px;
                      text-align: left;
                      color: #b9b9c3;
                      white-space: pre-line;
                    "
                  >
                    {{ data.item.details }}</span
                  >
                </div>
              </template>
              <template #cell(price_per_sqft)="data">
                <div
                  :class="[data.item.price_per_sqft < 0 ? 'credit' : '']"
                  style="text-align: center"
                >
                  {{
                              [(data.item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.price_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}
                </div>
              </template>
              <template #cell(cost_per_sqft)="data">
                <div class="cost-line" style="text-align: center">
                  {{
                    "$" +
                    Number(data.item.cost_per_sqft).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </div>
              </template>
              <template #head(cost_per_sqft)>
                <span class="cost-header">COGS / SQFT</span>
              </template>
              <template #cell(total_price)="data">
                <div
                  :class="[data.item.total_price < 0 ? 'credit' : '']"
                  style="text-align: center"
                >
                  {{
                              [(data.item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}
                </div>
              </template>
              <template #head(total_cost)>
                <span class="cost-header">Total COGS</span>
              </template>
              <template #cell(total_cost)="data">
                <div class="cost-line" style="text-align: center">
                  {{
                    "$" +
                    Number(data.item.total_cost).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row style="padding-top: 40px; padding-right: 15px; padding-left: 15px">
          <b-col>
            <h3
              style="
                height: 35px !important;
                font-weight: 500 !important;
                font-size: 21px !important;
                line-height: 21px !important;
              "
            >
              Additional Scope & Upgrades
            </h3>
            <b-table :fields="fields" :items="options" responsive="true">
              <template #table-busy>
                <div class="text-center text-primary my-2">
                  <b-spinner class="align-middle" />
                  <strong> Loading...</strong>
                </div>
              </template>

              <template #cell(name)="data">
                <div>
                  {{ data.item.name }} <br />
                  <span
                    v-if="data.item.details"
                    style="
                      font-size: 12px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 18px;
                      letter-spacing: 0px;
                      text-align: left;
                      color: #b9b9c3;
                      white-space: pre-line;
                    "
                  >
                    {{ data.item.details }}</span
                  >
                </div>
              </template>
              <template #cell(price_per_sqft)="data">
                <div
                  :class="[data.item.price_per_sqft < 0 ? 'credit' : '']"
                  style="text-align: center"
                >
                  {{
                              [(data.item.price_per_sqft &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.price_per_sqft))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}
                </div>
              </template>
              <template #cell(cost_per_sqft)="data">
                <div class="cost-line" style="text-align: center">
                  {{
                    "$" +
                    Number(data.item.cost_per_sqft).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </div>
              </template>
              <template #head(cost_per_sqft)>
                <span class="cost-header">COGS / SQFT</span>
              </template>
              <template #cell(total_price)="data">
                <div
                  :class="[data.item.total_price < 0 ? 'credit' : '']"
                  style="text-align: center"
                >
                  {{
                              [(data.item.total_price &lt; 0) ? '-' : ''] + '$' + Number(Math.abs(data.item.total_price))
                                  .toLocaleString(undefined, { minimumFractionDigits: 2 })
                  }}
                </div>
              </template>
              <template #head(total_cost)>
                <span class="cost-header">Total COGS</span>
              </template>
              <template #cell(total_cost)="data">
                <div class="cost-line" style="text-align: center">
                  {{
                    "$" +
                    Number(data.item.total_cost).toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                    })
                  }}
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </app-collapse-item>
    </app-collapse>
  </div>
</template>

<script>
import { UilFileExport } from "@iconscout/vue-unicons";
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
  BTable,
  BFormGroup,
  BFormRadioGroup,
  BInputGroup,
  BFormInput,
  BInputGroupPrepend,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItemDPMOutput.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
    AppCollapse,
    AppCollapseItem,
    BTable,
    BFormGroup,
    BFormRadioGroup,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    UilFileExport,
  },
  props: [
    "quote",
    "optionsAvItSubtotal",
    "optionsFfeSubtotal",
    "optionsConstructionSubtotal",
    "siteConditionsConstructionSubtotal",
    "options",
    "siteConditions",
  ],
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      comments: {},
      show: true,
      newComment: "",
      assumptionsExclusions: {},
      fields: [
        {
          key: "name",
          label: "ITEM",
          sortable: true,
        },
        {
          key: "price_per_sqft",
          label: "Price / SQFT",
          sortable: false,
        },
        {
          key: "total_price",
          label: "Total Price (w/ D&I)",
          sortable: true,
        },
        {
          key: "cost_per_sqft",
          label: "COGS / SQFT",
          sortable: true,
        },
        {
          key: "total_cost",
          label: "Total COGS",
          sortable: true,
        },
      ],
      fieldsFinishes: [
        {
          key: "name",
          label: "Finish",
          sortable: true,
        },
        {
          key: "qty",
          label: "QTY",
          sortable: false,
        },
        {
          key: "total_cost",
          label: "total COST",
          sortable: true,
        },
        {
          key: "cost_per_sqft",
          label: "COGS / Unit",
          sortable: true,
        },
      ],
      fieldsFfe: [
        {
          key: "name",
          label: "item",
          sortable: true,
        },
        {
          key: "element.subcategory",
          label: "subgroup",
          sortable: false,
        },
        {
          key: "element.vendor",
          label: "Vendor",
          sortable: true,
        },
        {
          key: "unit_price",
          label: "Price / Unit",
          sortable: true,
        },
        {
          key: "qty",
          label: "Qty",
          sortable: true,
        },
        {
          key: "total_price",
          label: "Total Price",
          sortable: true,
        },
      ],
      selectedRadio: "all",
      optionsRadio: [
        { text: "All FF&E", value: "all" },
        { text: "Closed Spec Ancillary", value: "closedSpec" },
        { text: "Open Spec Ancillary", value: "openSpec" },
        { text: "Clearspace", value: "clearspace" },
      ],
      filter: null,
    };
  },
  beforeMount() {},
  computed: {
    lightFixturesAmount() {
      const results = this.quote.construction_breakdown["Electrical (Takeoff)"].filter(
        (breakdown) => breakdown.construction_work_id === 29
      );
      const qty = results[0].qty;
      const adjustmentsResults = this.quote.options
        .filter((x) => x.construction_work_id == 29)
        .reduce((total, obj) => obj.quantity + total, 0);
      return qty + adjustmentsResults;
    },
    duplexReceptaclesAmount() {
      if (
        this.quote.construction_breakdown["Electrical (Takeoff)"].filter(
          (x) => x.construction_work_id === 27
        ).length > 0
      ) {
        const results = this.quote.construction_breakdown["Electrical (Takeoff)"]
          .filter((x) => x.construction_work_id === 27)
          .reduce((total, obj) => obj.qty + total, 0);
        const adjustmentsResults = this.quote.options
          .filter((x) => x.construction_work_id == 27)
          .reduce((total, obj) => obj.quantity + total, 0);
        return results + adjustmentsResults;
      } else {
        const results = this.quote.construction_breakdown["Electrical (Takeoff)"]
          .filter((x) => x.construction_work_id === 54 || x.construction_work_id === 55)
          .reduce((total, obj) => obj.qty + total, 0);
        const adjustmentsResults = this.quote.options
          .filter((x) => x.construction_work_id === 54 || x.construction_work_id === 55)
          .reduce((total, obj) => obj.quantity + total, 0);
        return results + adjustmentsResults;
      }
    },
    convenienceDuplexReceptaclesAmount() {
      if (
        this.quote.construction_breakdown["Electrical (Takeoff)"].filter(
          (x) => x.construction_work_id === 27
        ).length > 0
      ) {
        const results = Math.round(this.quote.sqft / 500);
        return results;
      } else {
        const results = this.quote.construction_breakdown["Electrical (Takeoff)"]
          .filter((x) => x.construction_work_id === 54)
          .reduce((total, obj) => obj.qty + total, 0);
        const adjustmentsResults = this.quote.options
          .filter((x) => x.construction_work_id === 54)
          .reduce((total, obj) => obj.quantity + total, 0);
        return results + adjustmentsResults;
      }
    },
    designFinishesTable() {
      return this.quote.construction_spec_quote.filter((n) =>
        [6, 10, 11, 12, 13, 33, 42, 43, 49, 44, 45, 49, 59, 60, 61, 50].includes(
          n.construction_work_id
        )
      );
    },
    ffeTableTotal() {
      const results = this.quote.ffe_spec_quote.reduce(
        (total, obj) => obj.supply_price * obj.qty + total,
        0
      );
      return results;
    },
    orizealTotal() {
      const results = this.quote.ffe_spec_quote
        .filter(
          (x) => x.element.vendor === "Orizeal" || x.element.vendor === "Clearspace"
        )
        .reduce((total, obj) => obj.supply_price * obj.qty + total, 0);
      return results;
    },
    openSpecTotal() {
      const results = this.quote.ffe_spec_quote
        .filter(
          (x) =>
            x.element.vendor === "[OPEN SPEC]" && x.element.subcategory === "Ancillary"
        )
        .reduce((total, obj) => obj.supply_price * obj.qty + total, 0);
      return results;
    },
    closedSpecTotal() {
      const results = this.quote.ffe_spec_quote
        .filter(
          (x) =>
            x.element.vendor !== "[OPEN SPEC]" && x.element.subcategory === "Ancillary"
        )
        .reduce((total, obj) => obj.supply_price * obj.qty + total, 0);
      return results;
    },
    ffeItems() {
      if (this.selectedRadio === "clearspace") {
        const results = this.quote.ffe_spec_quote.filter(
          (x) => x.element.vendor === "Orizeal" || x.element.vendor === "Clearspace"
        );
        return results;
      } else if (this.selectedRadio === "closedSpec") {
        const results = this.quote.ffe_spec_quote.filter(
          (x) =>
            x.element.vendor !== "[OPEN SPEC]" && x.element.subcategory === "Ancillary"
        );
        return results;
      } else if (this.selectedRadio === "openSpec") {
        const results = this.quote.ffe_spec_quote.filter(
          (x) =>
            x.element.vendor === "[OPEN SPEC]" && x.element.subcategory === "Ancillary"
        );
        return results;
      } else {
        const results = this.quote.ffe_spec_quote;
        return results;
      }
    },
    ffeTotalCost() {
      const results = this.quote.ffe_spec_quote.reduce(
        (total, obj) => obj.total_cost * 1 + total,
        0
      );
      return results;
    },
    avitTotalCost() {
      const results = this.quote.avit_spec_quote.reduce(
        (total, obj) => obj.total_cost * 1 + total,
        0
      );
      return results;
    },
    siteConditionsConstructionCostTotal() {
      const results = this.quote.site_conditions
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    siteConditionsConstructionPriceTotal() {
      const results = this.quote.site_conditions
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return results;
    },
    optionsConstructionCostTotal() {
      const results = this.quote.options
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    optionsConstructionPriceTotal() {
      const results = this.quote.options
        .filter((x) => x.budget_group === "Construction")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return results;
    },
    optionsAvItCostTotal() {
      const results = this.quote.options
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    optionsAvItPriceTotal() {
      const results = this.quote.options
        .filter((x) => x.budget_group === "AV/IT")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return results;
    },
    optionsFfeCostTotal() {
      const results = this.quote.options
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_cost * 1 + total, 0);
      return results;
    },
    optionsFfePriceTotal() {
      const results = this.quote.options
        .filter((x) => x.budget_group === "FF&E")
        .reduce((total, obj) => obj.total_price * 1 + total, 0);
      return results;
    },
    scAllowPriceSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
        console.log(result);
      return result;
    },
    scAllowCostSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Soft Cost")
        .reduce((total, obj) => Number(obj.total_cost) + total, 0);
        console.log(result);
      return result;
    },
  },

  methods: {
    tenderExport() {
      this.$http({
        url: `quote/tender-export/${this.quoteId}`,
        method: "GET",
        responseType: "blob",
      }).then((res) => {
        var fileURL = window.URL.createObjectURL(new Blob([res.data]));
        var fileLink = document.createElement("a");

        fileLink.href = fileURL;
        fileLink.setAttribute("download", `Quote #${this.quote.tfr_name}` + ".xlsx");
        document.body.appendChild(fileLink);

        fileLink.click();
      });
    },
    constructionAdjustmentTotalUnitCost(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.unit_cost);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    constructionAdjustmentTotalCost(constructionWorkId) {
      var sum = 0;
      this.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.total_cost);
        });
      return parseFloat(sum);
    },
    totalProfitValue(price, cost) {
      let res = 0;
      if (price < 0 && cost < 0) {
        res = price - cost;
      } else if (price > 0 && cost > 0) {
        res = Math.abs(price) - Math.abs(cost);
      } else if (price < 0 && cost > 0) {
        res = price - Math.abs(cost);
      } else if (price > 0 && cost < 0) {
        res = Math.abs(price) - cost;
      } else {
        res = price - cost;
      }
      if (res < 0) {
        return (
          " -$" + Math.abs(res).toLocaleString(undefined, { minimumFractionDigits: 2 })
        );
      } else {
        return " $" + res.toLocaleString(undefined, { minimumFractionDigits: 2 });
      }
    },
  },
};
</script>

<style scoped>
.cost-line {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  color: #b9b9c3;
}
.cost-header {
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  color: #b9b9c3;
}
</style>
