<template>
  <b-card
    v-if="this.quote.construction_spec_quote.length > 0"
    class="invoice-preview-card pagebreak"
    no-body
  >
    <!-- Header -->
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Estimate #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>

      <h2>Hard Construction</h2>
      <h5 class="text-muted mb-1" style="margin-top: 5px !important">Summary</h5>
      <!-- Hard Construction: Table -->
      <div class="">
        <b-table-simple
          fixed
          style="padding-right: 28px; padding-left: 28px; overflow-y: hidden"
        >
          <b-thead>
            <b-tr>
              <b-th class="table-background-color" colspan="9"> SERVICES </b-th>
              <b-th class="table-background-color text-right" colspan="2">
                {{ $t("quote.pricePerSqft", "en") }}
              </b-th>
              <b-th class="table-background-color text-right" colspan="2">
                {{ $t("quote.subtotal", "en") }}
              </b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr class="border-footer table_sub_header">
              <b-td class="row-label" colspan="9" style=""> Base Contract </b-td>
              <b-td class="row-label text-right" colspan="2"
                >{{
                  Number((ctrItemsSubtotal + addBcSubtotal) / quote.sqft).toLocaleString(
                    "en-CA",
                    {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    }
                  )
                }}
              </b-td>
              <b-td class="row-label text-right" colspan="2"
                >{{
                  Number(ctrItemsSubtotal + addBcSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
            <template v-if="quote.base_spec_version < 3.6">
              <template
                v-for="(c, index) in constCategories"
                v-if="
                  (hasItems(c.name) || additionsBC(c)) && quote.tender_breakdown_hidden
                "
              >
                <b-tr
                  class="border-group-details"
                  :key="'constC' + index"
                  v-if="categoryTotals(c.name) + additionsBcTotal(c) != 0"
                >
                  <b-td colspan="9">
                    <div class="ml-2">{{ c.name }}</div>
                  </b-td>
                  <b-td
                    :class="[categoryTotals(c) < 0 ? 'credit ' : ''] + 'row-label'"
                    colspan="2"
                    style="font-weight: normal"
                    class="text-muted text-right"
                  >
                    {{
                      Number(
                        (categoryTotals(c.name) + additionsBcTotal(c)) / quote.sqft
                      ).toLocaleString("en-CA", {
                        minimumFractionDigits: 2,
                        style: "currency",
                        currency: "CAD",
                      })
                    }}
                  </b-td>
                  <b-td
                    :class="[categoryTotals(c.name) < 0 ? 'credit ' : '']"
                    total
                    cost
                    colspan="2"
                    style="font-weight: normal"
                    class="text-muted text-right"
                  >
                    {{
                      Number(categoryTotals(c.name) + additionsBcTotal(c)).toLocaleString(
                        "en-CA",
                        {
                          minimumFractionDigits: 2,
                          style: "currency",
                          currency: "CAD",
                        }
                      )
                    }}
                  </b-td>
                </b-tr>
              </template>

              <b-tr
                class="border-group-details"
                v-if="genRequirements.length > 0 && quote.tender_breakdown_hidden"
              >
                <b-td colspan="9">
                  <div class="ml-2">General Requirements</div>
                </b-td>
                <b-td colspan="2" style="font-weight: normal"> </b-td>
                <b-td total cost colspan="2" style="font-weight: normal"> </b-td>
              </b-tr>
              <b-tr
                v-if="quote.tender_breakdown_hidden"
                class="border-group-details"
                v-for="(gr, index) in genRequirements"
                :key="'genReq' + index"
              >
                <b-td colspan="9">
                  <div class="ml-4">{{ gr.name }}</div>
                </b-td>
                <b-td
                  :class="
                    [categoryTotals('Site Supervision') < 0 ? 'credit ' : ''] +
                    'row-label'
                  "
                  colspan="2"
                  style="font-weight: normal"
                  class="text-muted text-right"
                >
                  {{
                    Number(
                      (Number(gr.total_price) +
                        Number(additionsBcGeneralRequirements(gr))) /
                        quote.sqft
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
                <b-td
                  total
                  cost
                  colspan="2"
                  style="font-weight: normal"
                  class="text-muted text-right"
                >
                  {{
                    Number(
                      Number(gr.total_price) + Number(additionsBcGeneralRequirements(gr))
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </template>
            <template
              v-for="(c, index) in cstrn_categories"
              v-if="
                cstrnUniformatItems(c).length > 0 &&
                quote.tender_breakdown_hidden &&
                quote.base_spec_version >= 3.6
              "
            >
              <b-tr
                class="border-group-details"
                :key="'constCa' + index"
                v-if="uniformattedCategoryTotals(cstrnUniformatItems(c)) > 0"
              >
                <b-td colspan="9">
                  <div class="ml-2">{{ c }}</div>
                </b-td>
                <b-td
                  colspan="2"
                  style="font-weight: normal"
                  class="text-muted text-right"
                >
                  {{
                    Number(
                      (uniformattedCategoryTotals(cstrnUniformatItems(c)) +
                        (c === "General Requirements" ? Number(cmFeeCalc) : 0)) /
                        quote.sqft
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
                <b-td
                  total
                  cost
                  colspan="2"
                  style="font-weight: normal"
                  class="text-muted text-right"
                >
                  {{
                    Number(
                      uniformattedCategoryTotals(cstrnUniformatItems(c)) +
                        (c === "General Requirements" ? Number(cmFeeCalc) : 0)
                    ).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </b-tr>
            </template>
          </b-tbody>
          <b-tbody v-if="ctrItems.length > 0">
            <b-tr class="table_sub_header">
              <b-td colspan="9"><b>Base Contract Additions </b></b-td>
              <b-td colspan="2" class="text-right">
                <b>{{
                  Number(ctrSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
              <b-td colspan="2" class="text-right"
                ><b>{{
                  Number(ctrSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
            </b-tr>
            <b-tr
              class="border-group-details"
              v-for="(c, index) in ctrItems"
              :key="'ctrI' + index"
            >
              <b-td colspan="9">
                <div class="ml-2">
                  {{ c.name }}
                  <div class="text-muted">
                    {{ c.details }}
                  </div>
                </div>
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : ''] + 'row-label'"
                colspan="2"
                style="font-weight: normal"
                class="text-muted text-right"
              >
                {{
                  Number(c.total_price / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
              <b-td
                :class="[c.total_price < 0 ? 'credit ' : '']"
                total
                cost
                colspan="2"
                style="font-weight: normal"
                class="text-muted text-right"
              >
                {{
                  Number(c.total_price).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}
              </b-td>
            </b-tr>
          </b-tbody>
          <b-tbody v-if="allowItems.length > 0">
            <b-tr class="table_sub_header">
              <b-td colspan="9"><b>Cash Allowances</b></b-td>
              <b-td colspan="2" class="text-right">
                <b>{{
                  Number(allowSubtotal / quote.sqft).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
              <b-td colspan="2" class="text-right"
                ><b>{{
                  Number(allowSubtotal).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
            </b-tr>
            <b-tr v-for="(a, index) in allowItems" :key="'alloCtr' + index">
              <template>
                <b-td colspan="9">
                  <div class="ml-2">
                    {{ a.name }}
                    <p class="text-muted">
                      {{ a.details }}
                    </p>
                  </div>
                </b-td>
                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(a.total_price / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>

                <b-td colspan="2" class="text-muted text-right">
                  {{
                    Number(a.total_price).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}
                </b-td>
              </template>
            </b-tr>
          </b-tbody>

          <b-tbody v-if="quote.base_spec_version >= 4.2 && quote.cm_fee_enabled">
            <b-tr class="table_sub_header">
              <b-td colspan="9"><b>Construction Management Fee</b></b-td>
              <b-td colspan="2" class="text-right">
                <b>{{
                    Number(cmFeeCalcUpdated / quote.sqft).toLocaleString("en-CA", {
                      minimumFractionDigits: 2,
                      style: "currency",
                      currency: "CAD",
                    })
                  }}</b></b-td
              >
              <b-td colspan="2" class="text-right"
              ><b>{{
                  Number(cmFeeCalcUpdated).toLocaleString("en-CA", {
                    minimumFractionDigits: 2,
                    style: "currency",
                    currency: "CAD",
                  })
                }}</b></b-td
              >
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </div>
      <!-- Spacer -->
      <b-table-simple
        fixed
        style="
          padding-right: 28px;
          padding-left: 28px;
          overflow-y: hidden;
          border-top: 2px solid rgba(50, 61, 76, 1);
        "
        table-class="text-nowrap"
      >
        <b-tfoot>
          <b-tr>
            <b-td
              class="row-label project-total-label"
              colspan="9"
              style="border-top: none"
            >
              <h4 style="font-weight: 600">Hard Construction Subtotal</h4>
            </b-td>
            <b-td
              class="row-label project-total-number text-right"
              colspan="2"
              style="border-top: none"
            >
              {{
                Number(
                  (Number(ctrSubtotal) +
                    Number(cmFeeCalcUpdated)+
                    Number(allowSubtotal) +
                    Number(addBcSubtotal) +
                    Number(ctrItemsSubtotal)) /
                    quote.sqft
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </b-td>
            <b-td
              class="project-total-number text-right"
              colspan="2"
              style="border-top: none"
            >
              {{
                Number(
                  Number(ctrSubtotal) +
                    Number(cmFeeCalcUpdated)+
                    Number(allowSubtotal) +
                    Number(addBcSubtotal) +
                    Number(ctrItemsSubtotal)
                ).toLocaleString("en-CA", {
                  minimumFractionDigits: 2,
                  style: "currency",
                  currency: "CAD",
                })
              }}
            </b-td>
          </b-tr>
        </b-tfoot>
      </b-table-simple>
    </b-card-body>

    <!-- Note -->
    <!-- Note -->
    <!-- <b-card-body class="invoice-padding pt-0">
                <span class="disclaimer">This pricing does not contemplate liquidated damages. If liquidated damages are required, a premium will apply.<br>
                    Quote does NOT include <span v-if="quote.opportunity.geography == 'Toronto'">HST.</span><span v-else>include GST + QST.</span> <br>
                    Quote does NOT include any landlord required reviews and backcharges (incl. after hour fees, commissioning, security, engineer reviews, etc.).<br>
                    Changes to the scope of work listed above will be priced separately and agreed to in writing before additional work is performed. </span>
            </b-card-body> -->
    <b-card-body class="invoice-padding notprintable pt-0 footer-quote my-3">
      <span class="confidential-line float-right">{{
        $t("quote.confidential", "en")
      }}</span>
    </b-card-body>
    <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
  </b-card>
</template>

<script>
import uniformat_categories from "@/assets/objects/uniformat-codes-with-categories.json";
import {
  BCard,
  BCardBody,
  BCol,
  BButton,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCardBody,
    BCol,
    BButton,
    BRow,
    BTableSimple,
    BTbody,
    BTd,
    BTfoot,
    BTh,
    BThead,
    BTr,
  },
  props: {
    quote: {
      type: Object,
      default: [],
    },
    siteConditionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    optionsAvItSubtotal: {
      type: Number,
      default: 0,
    },
    optionsConstructionSubtotal: {
      type: Number,
      default: 0,
    },
    options: {
      type: Array,
      default: 0,
    },
    showOptions: {
      type: Boolean,
      default: 0,
    },
    siteConditions: {
      type: Array,
      default: 0,
    },
    showSiteConditions: {
      type: Boolean,
      default: 0,
    },
    optionsctrSubtotal: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      constCategories: [
        { name: "Demolition", min: "0001", max: "0002" },
        { name: "Drywall", min: "0100", max: "0199" },
        { name: "Glazing", min: "0200", max: "0299" },
        { name: "Flooring & Tile", min: "0300", max: "0399" },
        { name: "Electrical", min: "0400", max: "0499" },
        { name: "Mechanical", min: "0500", max: "0599" },
        { name: "Kitchen & Millwork", min: "0600", max: "0699" },
        { name: "Wall Finishes", min: "0700", max: "0799" },
        { name: "Security & Doors", min: "0800", max: "0899" },
        { name: "Closeout & Other", min: "1000", max: "1099" },
      ],
      uniformat_categories: uniformat_categories,
      cstrn_categories: [
        "Demolition",
        "Partitions",
        "Ceiling",
        "Electrical",
        "Plumbing",
        "HVAC",
        "Flooring",
        "Glazing",
        "Life Safety",
        "Wall Finishes",
        "Doors & Security",
        "Millwork & Kitchen",
        "General Requirements",
        "Other",
      ],
      ffeCategories: ["Systems Furniture", "Ancillary"],
      genRequirements: [],
      // get codes from uniformat-codes-with-categories.json
      excluded_codes_contingency_fee: [
        "Z1070.30.01",
        "Z1070.30.02",
        "Z1070.30.03",
        "Z1070.40",
        "Z1070.50",
        "Z1070.60",
        "Z1070.80",
        "Z1050.20",
        "Z1050.25",
        "Z1050.30",
        "Z1020.10",
      ],

      excluded_codes_contingency_fee_v2:
          [
            "Z1070.30.03",
            "Z1070.60",
            "Z1020.10",
            "Z1020.10",
            "Z1070.30.02",
            "Z1070.30.02",
            "Z1070.60",
            "Z1050.35",
            "Z1070.20",
            "Z1010.10"
          ],
    };
  },
  computed: {
    ctrSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && !c.is_base_spec)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    addBcSubtotal() {
      let result = this.quote.options
        .filter((c) => c.budget_group == "Construction" && c.is_base_spec)
        .reduce(
          (total, obj) =>
            Number(obj.total_price) + parseFloat(this.contingencyFeeCalc(obj)) + total,
          0
        );
      return result;
    },
    ctrItems() {
      let result = this.quote.options.filter(
        (c) => c.budget_group == "Construction" && !c.is_base_spec
      );
      return result;
    },
    allowItems() {
      let result = this.quote.allowances.filter((c) => c.budget_group == "Construction");
      return result;
    },
    allowSubtotal() {
      let result = this.quote.allowances
        .filter((c) => c.budget_group == "Construction")
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    ctrItemsSubtotal() {
      let result = this.quote.construction_spec_quote.reduce(
        (total, obj) => Number(obj.total_price) + this.contingencyFeeCalc(obj) + total,
        0
      );
      if (this.quote.cm_fee_enabled && this.quote.base_spec_version<4.2) {
        result += Number(this.cmFeeCalc * 1);
      }
      return result;
    },
    // change logic to calculate cmfee calc
    // ticket https://www.notion.so/clearspace1/Contingency-CM-Fee-Formulas-302caff0f00b4c0d999e65f43f025d4b
    baseSpecItemsSubTotal() {
      var sum = 0;
      // add validation for cm fee and cstrn margin per item
      this.quote.construction_spec_quote.forEach((e) => {
        sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
      });

      this.quote.options
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price) + parseFloat(this.contingencyFeeCalc(e));
        });

      this.quote.site_conditions
        .filter((n) => n.is_base_spec == true && n.budget_group == "Construction")
        .forEach((e) => {
          sum += parseFloat(e.total_price);
        });
      return Number(sum).toFixed(2);
    },
    // cm fee calc
    cmFeeCalc() {
      let result = 0;
      if (this.quote.base_spec_version > 3.6 && this.quote.base_spec_version <=4.1 ) {
        result = this.quote.cm_fee_enabled
          ? Number(this.baseSpecItemsSubTotal) *
            (Number(this.quote.cm_fee_percentage) / 100)
          : 0;
      }
      return result;
    },
    //function to apply cm fee globally for quotes 4.2 and above
    cmFeeCalcUpdated() {
      let result = 0;
      const { base_spec_version, cm_fee_enabled, cm_fee_percentage, construction_spec_quote, options, allowances } = this.quote;
      const cmFeeMultiplier = Number(cm_fee_percentage) / 100;

      if (base_spec_version >= 4.2 && cm_fee_enabled) {
        // Calculate for construction_spec_quote
        result += construction_spec_quote.reduce(
            (total, o) => total + ((o.total_price*1 + this.contingencyFeeCalc(o))),
            0
        )* cmFeeMultiplier;
        // Calculate for options with budget_group 'Construction'
        result += options
            .filter(x => x.budget_group === 'Construction' && x.is_base_spec)
            .reduce((total, o) => total + ((o.total_price*1 + this.contingencyFeeCalc(o)*1)), 0)* cmFeeMultiplier;
        result += options
            .filter(x => x.budget_group === 'Construction' && !x.is_base_spec)
            .reduce((total, o) => total + ((o.total_price*1 )), 0)* cmFeeMultiplier;

        // Calculate for allowances with budget_group 'Construction'
        result += allowances
            .filter(x => x.budget_group === 'Construction')
            .reduce((total, o) => total + ((o.total_price*1 )), 0)* cmFeeMultiplier;
      }
      return result;
    },
  },
  mounted() {
    this.genRequirements = this.quote.construction_spec_quote.filter(
      (x) => x.tender_category_name == "General Requirements"
    );
  },
  methods: {
    hasItems(category) {
      let has = false;
      let result = this.quote.construction_spec_quote.filter(
        (x) => x.tender_category_name == category
      );
      if (result.length > 0) {
        has = true;
      }
      return has;
    },
    cstrnUniformatItems(category) {
      let uniformat_codes_filtered = this.uniformat_categories.filter(
        (u) => u.category == category
      );
      let result = [];
      if (uniformat_codes_filtered.length > 0) {
        uniformat_codes_filtered.map((c) => {
          let res = this.quote.construction_spec_quote.filter(
            (x) => x.uniformat == c.uniformat
          );
          if (res.length > 0) {
            res.map((r) => {
              result.push(r);
            });
          }
        });
      }
      if (uniformat_codes_filtered.length > 0) {
        uniformat_codes_filtered.map((c) => {
          let res = this.quote.options.filter(
            (x) => x.uniformat == c.uniformat && x.is_base_spec
          );
          if (res.length > 0) {
            res.map((r) => {
              result.push(r);
            });
          }
        });
      }
      return result;
    },
    uniformattedCategoryTotals(category_elements) {
      // * filtering base spec items in order to apply cm fees and contingency fees
      return category_elements.reduce((total, obj) => {
        const priceWithContingency = Number(obj.total_price) + this.contingencyFeeCalc(obj);

        if (!obj.is_base_spec) {
          // Apply for non-base spec items
          return total + priceWithContingency;
        }

        if (obj.is_base_spec && !obj.is_adjustment && !obj.is_spec_swap) {
          // Apply for base spec items (not adjustment or swap)
          return total + priceWithContingency;
        }

        if (obj.is_adjustment || obj.is_spec_swap) {
          // Apply for spec swaps or custom specifications
          return total + priceWithContingency;
        }

        return total;
      }, 0);
    },
    categoryTotals(category) {
      let result = this.quote.construction_spec_quote
        .filter((x) => x.tender_category_name == category)
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    additionsBC(category) {
      let has = false;
      let result = this.quote.options.filter(
        (c) =>
          Number(c.tender_code) <= Number(category.max) &&
          Number(c.tender_code) >= Number(category.min) &&
          c.is_base_spec
      );
      if (result.length > 0) {
        has = true;
      }
      console.log(result);
      return has;
    },
    additionsBcGeneralRequirements(category) {
      let result = this.quote.options
        .filter(
          (c) =>
            c.budget_group == "Construction" &&
            c.is_base_spec &&
            c.tender_code == category.tender_code
        )
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
      return result;
    },
    additionsBcTotal(category) {
      return this.quote.options
        .filter(
          (c) =>
            c.budget_group == "Construction" &&
            c.is_base_spec &&
            Number(c.tender_code) <= Number(category.max) &&
            Number(c.tender_code) >= Number(category.min)
        )
        .reduce((total, obj) => Number(obj.total_price) + total, 0);
    },
    initCstrnCategories() {
      // Use Set to store unique category names
      const uniqueCategories = new Set();

      // Iterate over the data and add unique category names to the set
      this.uniformat_categories.forEach((item) => {
        uniqueCategories.add(item.category);
      });
      // Convert Set to an array to get unique category names
      const uniqueCategoryNames = Array.from(uniqueCategories);
    },

    // calculate base spec adjustment qty
    constructionAdjustmentTotalQuantity(constructionWorkId) {
      var sum = 0;
      this.quote.options
        .filter((n) => n.construction_work_id == constructionWorkId)
        .forEach((e) => {
          sum += parseFloat(e.quantity);
        });
      return parseFloat(sum);
    },
    contingencyFeeCalc(construction_spec) {
      let result =
        this.quote.base_spec_version > 3.6
          ? Number(
              Number(construction_spec.total_cost) *
                (this.quote.contingency_fee_percentage / 100)
            )
          : 0;
      if (
          this.excluded_codes_contingency_fee.filter(
              (e) => e == construction_spec.uniformat
          ).length > 0 && this.quote.base_spec_version > 3.6 && this.quote.base_spec_version <=4.1
      ) {
        result = 0;
      }
      if (
          this.excluded_codes_contingency_fee_v2.filter(
              (e) => e == construction_spec.uniformat
          ).length > 0 && this.quote.base_spec_version >= 4.2
      ) {
        result = 0;
      }
      // create custom adjustment for elements that qty is adjusted to 0
      // if (
      // (  construction_spec.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) )==
      //   0
      // ) {
      //   result = 0;
      // }
      // let cstrn_iten_filter = this.quote.construction_spec_quote.find((c)=>c.construction_work_id==construction_spec.construction_work_id);
      // if (cstrn_iten_filter&&
      //   cstrn_iten_filter.qty +
      //     this.constructionAdjustmentTotalQuantity(
      //       construction_spec.construction_work_id
      //     ) ==
      //   0
      // ) {
      //   result = 0;
      // }
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
[dir] .table:not(.table-dark):not(.table-light) thead:not(.thead-dark) th,
[dir] .table:not(.table-dark):not(.table-light) tfoot:not(.thead-dark) th {
  color:white !important;
}
</style>
