<template>
  <b-card class="invoice-preview-card pagebreak" no-body>
    <b-card-body class="invoice-padding pb-0">
      <div
        class="d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
      >
        <!-- Header: Left Content -->
        <div />

        <!-- Header: Right Content -->
        <div class="mt-md-0 mt-2">
          <h4 class="invoice-numbercs mb-2">Quote #{{ quote.tfr.tfr }}</h4>
        </div>
      </div>
      <div>
        <h2
          style="
            padding-bottom: 1rem;
            font-size: 28px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          "
        >
          {{$t('assumptionsExclusions.assumptionsExclusionsAndNotes', "en")}}
        </h2>

         <div class="pb-4 " style="overflow-y: hidden">

                <h3 class="font-weight-bold">{{$t('assumptionsExclusions.assumedExistingSiteConditions', "en")}}
                    <br />
                    <small class="disclaimer-exclusions">{{$t('assumptionsExclusions.disclaimerExclusion', "en")}}</small>
                </h3>
                <div>
                    <div class="d-flex justify-content-between align-items-center first-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                           {{$t('assumptionsExclusions.maxHeight', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            11.5 {{$t('assumptionsExclusions.ft', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border" v-if="quote.geography != 'Montréal'">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.freeofUnionLabour', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.union_labour_free == 1 ? $t('assumptionsExclusions.yes', "en") : $t('assumptionsExclusions.no', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.clearRaisedAccessFlooring', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.raised_access_floor_clear == 1 ? $t('assumptionsExclusions.yes', "en") : $t('assumptionsExclusions.no', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.cleanAndClearFFe', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.ffe_clear == 1 ? $t('assumptionsExclusions.yes', "en") : $t('assumptionsExclusions.no', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                             {{$t('assumptionsExclusions.siteConditionMeetsBuildingCode', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.meets_building_code == 1 ? $t('assumptionsExclusions.yes', "en") : $t('assumptionsExclusions.no', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.baseBuildingEquipment', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.building_equipment_good_condition == 1 ? $t('assumptionsExclusions.yes', "en") : $t('assumptionsExclusions.no', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.demisingInteriorPartitions', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.built_demising_interior_partitions == 1 ? $t('assumptionsExclusions.yes', "en") : $t('assumptionsExclusions.no', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.siteClearHazardousMaterial', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.hazardous_material_clear == 1 ? $t('assumptionsExclusions.yes', "en") : $t('assumptionsExclusions.no', "en")}}
                        </b-col>
                    </div>
                </div>
            </div>
            <div class="pb-4 " style="overflow-y: hidden; padding-bottom:90px !important">
                <h3 class="font-weight-bold"> {{$t('assumptionsExclusions.exclusionsOutOfScope', "en")}}
                    <br />
                    <small class="disclaimer-exclusions">{{$t('assumptionsExclusions.disclaimerExclusion', "en")}}</small>
                </h3>
                <div>
                    <div class="d-flex justify-content-between align-items-center first-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                             {{$t('assumptionsExclusions.demolition', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.demolition == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.accessControldevices', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.access_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.basSpecificSystems', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.bas_systems_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.electricalPanelTransformer', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.electrical_panel_transformer_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.additionalDemisingWalls', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.aditional_demising_walls_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.perimeterBulkhead', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.perimeter_bulkhead_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.blinds', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.blinds_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.suiteEntryDoors', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.suite_entry_doors_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.washroomWork', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.washroom_work_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.elevatorLobbyWork', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.elevator_lobby_work_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling first-column" cols="10">
                            {{$t('assumptionsExclusions.abatementHazardousMaterial', "en")}}
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling secondary-column" cols="2">
                            {{ this.assumptionsExclusions.abatement_hazardous_material_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling-double first-column" cols="10" v-html="$t('assumptionsExclusions.landlordRequiredReviews', 'en')">
                            
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling-double secondary-column" cols="2">
                            {{ this.assumptionsExclusions.landlord_required_reviews_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>
                    <div class="d-flex justify-content-between align-items-center following-row-border">
                        <b-col align-self="start" class="d-flex align-items-center cell-styling-double first-column" cols="10" v-html="$t('assumptionsExclusions.fireproofing', 'en')">
                        </b-col>
                        <b-col align-self="end" class="d-flex align-items-center justify-content-start cell-styling-double secondary-column" cols="2">
                            {{ this.assumptionsExclusions.fireproofing_spray_included == 1 ? $t('assumptionsExclusions.included', "en") : $t('assumptionsExclusions.notIncluded', "en")}}
                        </b-col>
                    </div>                                                                                                             
                </div>
            </div> 
        <hr>
        <p class="other-notes-title">
           {{$t('assumptionsExclusions.otherNotes', "en")}}
        </p>
        <p class="other-notes-text" v-html="$t('assumptionsExclusions.otherNotesText', 'en')">			
        </p> 
      </div>
      <b-card-body class="invoice-padding pt-0 footer-quote my-3 notprintable">
        <span class="confidential-line float-right">{{
          $t("quote.confidential", "en")
        }}</span>
      </b-card-body>
    </b-card-body>
                <hr class="bg-primary p-0 m-0 hr_bottom" style="height: 8px" />
  </b-card>
</template>

<script>
import {
  BButton,
  BCard,
  BCardBody,
  BCol,
  BRow,
  BTableSimple,
  BTbody,
  BTd,
  BTfoot,
  BTh,
  BThead,
  BTr,
  VBToggle,
} from "bootstrap-vue";
import Logo from "@core/layouts/components/Logo.vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  directives: {
    Ripple,
    "b-toggle": VBToggle,
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BTableSimple,
    BButton,
    BTr,
    BTh,
    BThead,
    BTfoot,
    BTd,
    BTbody,
    Logo,
  },
  props: ["quote"],
  data() {
    return {
      quoteId: this.$route.params.quoteid,
      comments: {},
      options: {},
      siteConditions: {},
      show: true,
      newComment: "",
      assumptionsExclusions: {},
    };
  },
  beforeMount() {
    this.assumptionsExclusions = this.quote.assumptions_exclusions;
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
.notes_assumptions_list > li {
  //   font-family:  "DM Sans";
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  color: #6e6b7b;
  padding: 10px 5px;
}
</style>
<style>
.cell-styling {
  height: 33px;
}
.cell-styling-double {
  height: 50px;
}
.first-column {
  padding-right: 28px;
  padding-left: 28px;
}
.secondary-column {
  padding-right: 28px;
  padding-left: 28px;
}
.first-row-border {
  border-top: 1px solid #ebe9f1;
  border-bottom: 1px solid #ebe9f1;
}
.following-row-border {
  border-bottom: 1px solid #ebe9f1;
}
.disclaimer-exclusions {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #ea5455;
}
.other-notes-title {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 23px;
  /* identical to box height, or 192% */
  padding-top: 29px;
  letter-spacing: 0.6px;
  text-transform: uppercase;
}
.other-notes-text {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}
.card-body {
  padding: 28px;
}
</style>
